import React, { useState } from "react";
import Contact from "../contact";
import arrowIcon from "../../images/home/arrow-icon.svg";
import smileyCouple from "../../images/individual/Patner-Visa.jpg";
import toggleIcon from "../../images/individual/fa-solid_stream.svg";
import rightVector from "../../images/individual/right-vector.svg";
import applicationProcess from "../../images/individual/application-proccess.svg";
import helpIcon from "../../images/individual/helpIcon.svg";
import { FaPlus, FaTimes } from "react-icons/fa";
import { Helmet } from "react-helmet-async";

const PartnerVisa = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div>
      <Helmet>
        <title>
          {" "}
          Partner Visa Consultants in Australia|Immiget Global Australia
        </title>
        <meta
          name="description"
          content="Immiget Global Australia provides expert guidance for spouse visas. Let our partner visa consultants help you reunite with your loved one."
        />
      </Helmet>
      <div className="w-full h-90 font-Hellix bg-cover bg-center bg-image">
        <div className="max-w-[1440px] px-4 md:px-[75px] m-auto h-full">
          <div className="flex flex-col gap-2 md:gap-2 pt-10 md:pt-20 pb-[90px] h-full justify-center">
            <div className="text-white  hellix-font  text-center font-semibold text-[22px] md:text-[54px]">
              Partner Visa (Subclass 820/801 and Subclass 309/100) 
            </div>
            <div className="flex justify-center  gap-2 md:gap-4">
              <div className="text-white  hellix-font text-[10px]  lg:text-sm flex items-center md:text-lg font-medium">
                HOME
              </div>
              <div className="flex items-center">
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
              </div>
              <div className="text-white hellix-font text-[10px]  lg:text-sm flex items-center md:text-lg font-medium uppercase">
                Individual & Families
              </div>
              <div className="flex items-center">
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
              </div>
              <div className="text-white hellix-font  text-[10px]  lg:text-sm flex items-center md:text-lg font-medium uppercase">
                Partner Visa (Subclass 820/801 and Subclass 309/100)
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Partner Visa (Subclass 820/801 and Subclass 309/100) */}
      <div className="xl:flex gap-4 justify-center container max-w-1440px  mx-auto px-4  py-4 md:pt-10 lg:px-14">
        <div className="xl:w-[60%] ">
          <div className="xl:text-[38px] text-[24px] max-xl:text-center text-blue mt-6 font-semibold hellix-font">
            Australia Partner Visa
          </div>
          <div className=" max-xl:text-center hellix-font text-blue text-lg xl:text-[22px] font-normal ">
            The Australian Partner Visa allows spouses or de facto partners of
            Australian citizens, permanent residents, or eligible New Zealand
            citizens to live and work in Australia. This visa is available both
            for applicants in Australia (Subclass 820/801) and those offshore
            (Subclass 309/100). At Immiget Global, we provide tailored guidance
            throughout the complex application stages, helping you and your
            partner navigate eligibility requirements, evidence preparation, and
            application submission to achieve a successful visa outcome. 
          </div>
        </div>
        <div className="xl:w-[40%] max-xl:justify-center max-xl:flex max-xl:mt-4">
          <img src={smileyCouple} alt="banner-image" />
        </div>
      </div>

      {/* Eligibility Criteria   */}
      <div className="container max-w-1440px mx-auto px-4 py-6 xl:pb-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={toggleIcon} alt="toggle Icon" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            Who is Eligible for a Partner Visa?
          </div>
        </div>
        <div className="lg:ml-4 mt-4">
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              The applicant must be married or in a de facto relationship with
              an eligible sponsor. 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px]  font-normal hellix-font">
              Evidence of a genuine, ongoing relationship is essential, covering
              aspects such as shared finances, household arrangements, social
              recognition, and long-term commitment.  {" "}
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px]  font-normal hellix-font">
              Both applicants and sponsors must meet character and health
              requirements.
            </div>
          </div>
        </div>
      </div>

      {/* Understanding the Partner Visa Process    */}
      <div className=" container max-w-1440px  mx-auto px-4  lg:px-14">
        <div className="flex gap-3">
          <div className="flex items-center">
            <img src={applicationProcess} alt=" Application Process" />
          </div>
          <div className="lg:text-[32px] text-2xl  text-blue font-semibold hellix-font">
            Step-by-Step Guide to the Partner Visa Process
          </div>
        </div>
        <div className="hellix-font text-lg lg:text-[22px] font-normal text-blue py-3">
          The Partner Visa application is a two-stage process, beginning with a
          temporary visa (Subclass 820 or 309) and leading to permanent
          residency (Subclass 801 or 100) upon satisfying additional
          requirements. Processing time varies, and applicants must demonstrate
          a genuine, ongoing relationship with their partner. 
        </div>
        <div className=" lg:ml-8 hellix-font mt-3">
          <div className="hellix-font text-lg lg:text-[22px] font-semibold text-blue">
            Step 1: Application Process and Stages
          </div>
          <ul className="list-inside list-disc pl-5 hellix-font text-lg lg:text-[22px] font-normal text-blue py-2">
            <li className="flex items-start">
              <span className="mr-2">•</span>
              <span>
                Temporary Visa Stage (820 or 309): The initial visa grants
                temporary residence in Australia, allowing the applicant to work
                and access some public services.
              </span>
            </li>
            <li className="flex items-start">
              <span className="mr-2">•</span>
              <span>
                Permanent Visa Stage (801 or 100): After two years, if the
                relationship remains intact, the applicant may apply for
                permanent residency. Additional evidence of an ongoing
                relationship is generally required at this stage.
              </span>
            </li>
          </ul>

          <div className="hellix-font text-lg lg:text-[22px] font-semibold text-blue">
            Step 2: Key Documentation
          </div>
          <ul className="list-inside list-disc pl-5 hellix-font text-lg lg:text-[22px] font-normal text-blue py-2">
            <li className="flex items-start">
              <span className="mr-2">•</span>
              <span>
                Relationship evidence (statutory declarations, photos, joint
                finances, etc.).
              </span>
            </li>
            <li className="flex items-start">
              <span className="mr-2">•</span>
              <span>Proof of cohabitation or relationship recognition.</span>
            </li>
            <li className="flex items-start">
              <span className="mr-2">•</span>
              <span>
                Character assessments for both applicant and sponsor, including
                police checks.
              </span>
            </li>
          </ul>
        </div>
      </div>

      {/* How Immiget Global Can Assist  */}
      <div className="container max-w-1440px mx-auto px-4 py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={helpIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            How Immiget Global Makes Your Partner Visa Journey Easy
          </div>
        </div>
        <div className="text-blue font-normal text-lg lg:text-[22px] mt-2 hellix-font">
          Our team ensures that every detail of your relationship evidence
          aligns with immigration requirements. We provide end-to-end support,
          from documentation preparation to managing additional requirements
          during the two-year waiting period. 
        </div>
      </div>

      {/*FAQ*/}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10  lg:px-14">
        <div className="lg:text-[38px] text-2xl text-center leading-10 text-blue font-semibold hellix-font">
          FAQs – Everything You Need to Know About Partner Visas
        </div>
        <div className="text-blue font-normal text-xl lg:text-[22px] text-center  hellix-font pt-3">
          Our migration agents are experts in navigating complex processes,
          offering tailored solutions with professionalism and care. Trust us to
          provide transparent, efficient, and reliable support for a seamless
          journey to your new destination.
        </div>

        <div className="accordion-container mt-8">
          {/* Accordion Section 1 */}
          <div className="accordion">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(1)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                1. Fast & Hassle-Free Spouse Visa for Australia with Immiget
                Global Australia! 
              </h2>
              {activeIndex === 1 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 1 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  A visa for spouses, partners, or fiancés of Australian
                  citizens, permanent residents, or eligible New Zealand
                  citizens.  
                </p>
              </div>
            )}
          </div>

          {/* Accordion Section 2 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(2)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                2. What are the subclasses for Partner Visas? 
              </h2>
              {activeIndex === 2 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 2 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Subclass 820/801 (onshore) and Subclass 309/100 (offshore).
                </p>
              </div>
            )}
          </div>

          {/* Accordion Section 3 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(3)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                3. Who is eligible for the Partner Visa? 
              </h2>
              {activeIndex === 3 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 3 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Spouses, partners, or fiancés of Australian citizens,
                  permanent residents, or eligible New Zealand citizens.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 4 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(4)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                4. What are the relationship requirements? 
              </h2>
              {activeIndex === 4 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 4 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Genuine and ongoing relationship, living together, or
                  intention to marry.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(5)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                5. How do I apply for the Partner Visa? 
              </h2>
              {activeIndex === 5 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 5 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Sponsor submits sponsorship application, followed by
                  applicant's visa application.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 6 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(6)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                6. What documents are required? 
              </h2>
              {activeIndex === 4 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 6 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Passport, birth certificate, marriage certificate (if
                  applicable), proof of relationship.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 7 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(7)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                7. How long does the Partner Visa application process take? 
              </h2>
              {activeIndex === 7 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 7 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Typically 12-24 months.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 8 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(8)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                8. What are the benefits of the Partner Visa? 
              </h2>
              {activeIndex === 8 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 8 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lgfont-normal hellix-font">
                  Temporary and permanent residence, work, and study in
                  Australia.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 9 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(9)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                9. Who can sponsor a Partner Visa applicant? 
              </h2>
              {activeIndex === 9 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 9 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Australian citizens, permanent residents, or eligible New
                  Zealand citizens.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 10 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(10)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                10. What health and character requirements apply to the Partner
                Visa? 
              </h2>
              {activeIndex === 10 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 10 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Standard health and character requirements.
                </p>
              </div>
            )}
          </div>

          {/* Accordion Section 11 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(11)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                11. How can Immiget Global assist with my Partner Visa
                application?  
              </h2>
              {activeIndex === 11 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 11 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Expert guidance on eligibility, document preparation, and
                  application submission.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <Contact />
    </div>
  );
};

export default PartnerVisa;

import React, { useState } from "react";
import Contact from "../contact";
import arrowIcon from "../../images/home/arrow-icon.svg";
import ellipticalIcon from "../../images/individual/ellipticalIcon.svg";
import rightVector from "../../images/individual/right-vector.svg";
import benifitIcon from "../../images/individual/benifitIcon.png";
import permanentIcon from "../../images/individual/permanent-resident.svg";
import helpIcon from "../../images/individual/helpIcon.svg";
import { FaPlus, FaTimes } from "react-icons/fa";
import unlimitedIcon from "../../images/individual/ic_outline-home-work.svg";
import iconPeople from "../../images/individual/akar-icons_people-group.svg";
import studentGradianVisa from "../../images/individual/SC-590--Student-Guardian-Visa.jpg";
import guardianImage from "../../images/individual/guardian.png";

const StudentGuardianVisa = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div>
      <div className="w-full h-90 font-Hellix bg-cover bg-center bg-image">
        <div className="max-w-[1440px] px-4 md:px-[75px] m-auto h-full">
          <div className="flex flex-col gap-2 md:gap-2 pt-10 md:pt-20 pb-[90px] h-full justify-center">
            <div className="text-white  hellix-font  text-center font-semibold text-[22px] md:text-[54px]">
              Subclass 590 Student Guardian Visa 
            </div>
            <div className="flex justify-center  gap-2 md:gap-4">
              <div className="text-white  hellix-font text-[10px]  lg:text-sm flex items-center md:text-lg font-medium">
                HOME
              </div>
              <div className="flex items-center">
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
              </div>
              <div className="text-white hellix-font text-[10px]  lg:text-sm flex items-center md:text-lg font-medium uppercase">
                Individual & Families
              </div>
              <div className="flex items-center">
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
              </div>
              <div className="text-white hellix-font  text-[10px]  lg:text-sm flex items-center md:text-lg font-medium uppercase">
                Subclass 590 Student Guardian Visa 
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Subclass 189 Visa: Skilled Independent Visa*/}
      <div className="xl:flex gap-4 justify-center container max-w-1440px  mx-auto px-4  py-4 md:py-10 lg:px-14">
        <div className="xl:w-[50%] ">
          <div className="xl:text-[38px] text-[24px] max-xl:text-center text-blue mt-6 font-semibold hellix-font">
            Subclass 590 Student Guardian Visa 
          </div>
          <div className=" max-xl:text-center hellix-font text-blue text-lg xl:text-[22px] font-normal ">
            The Subclass 590 Student Guardian Visa is designed for parents,
            legal guardians, or relatives of international students under 18
            studying in Australia. This visa allows guardians to live in
            Australia to support the student and ensure their well-being
            throughout their studies. 
          </div>
        </div>
        <div className="xl:w-[50%] max-xl:justify-center max-xl:flex max-xl:mt-4">
          <img src={studentGradianVisa} alt="banner-image" />
        </div>
      </div>
      <div className="container max-w-1440px  mx-auto px-4   lg:px-14">
        <div style={{ border: "1px solid #cde1ea" }}></div>
      </div>
      {/* Eligibility Criteria:  */}
      <div className=" container max-w-1440px  mx-auto px-4 py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={ellipticalIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px]  text-2xl text-blue font-semibold hellix-font">
            Eligibility Criteria:
          </div>
        </div>

        <div className="lg:ml-4 mt-4">
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px] font-normal hellix-font">
              <span className="font-medium">
                {" "}
                Relationship with the Student:{" "}
              </span>{" "}
              Must be a parent, custodian, or eligible relative of a student
              visa holder under 18 years. 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              <span className="font-medium"> Sufficient Funds:</span>{" "}
              Demonstrate financial ability to support both the guardian and the
              student during their stay in Australia. 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              <span className="font-medium">
                {" "}
                Health and Character Requirements:{" "}
              </span>{" "}
              Must meet health and character standards set by Australian
              immigration. 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              <span className="font-medium"> Temporary Intent:</span> Show
              genuine intention to stay in Australia temporarily as a student
              guardian. 
            </div>
          </div>
        </div>
      </div>

      {/*Benefits  */}
      <div className=" container max-w-1440px  mx-auto px-4 py-6 xl:py-10  lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={benifitIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            Key Benefits
          </div>
        </div>
        <div className="text-blue font-normal text-lg  lg:text-[22px] mt-2 hellix-font">
          Many Employer Sponsor Visa pathways offer options for permanent
          residency, making it a viable long-term settlement solution.
        </div>
        <div className="flex flex-wrap justify-between mt-8  ">
          <div className="shadow-custom-white  mt-4 flex flex-col gap-1  rounded-[16px] justify-center items-center w-[410px] h-[240px]">
            <div>
              <img src={permanentIcon} alt="permanent-icon" />
            </div>
            <div className="text-blue text-xl font-semibold text-center  hellix-font">
              Stay with the Student:
            </div>
            <div className="text-blue text-xl font-normal text-center  hellix-font">
              Reside in Australia to support and accompany a minor studying in
              Australia. 
            </div>
          </div>
          <div className="shadow-custom-white  mt-4  flex flex-col gap-1 rounded-[16px] justify-center items-center w-[410px] h-[240px]">
            <div>
              <img src={unlimitedIcon} alt="permanent-icon" />
            </div>
            <div className="text-blue text-xl font-semibold text-center  hellix-font">
              Multiple Entries:
            </div>
            <div className="text-blue text-xl px-2 font-normal text-center hellix-font">
              Enter and leave Australia as required while the visa is valid. 
            </div>
          </div>
          <div className="shadow-custom-white mt-4  flex flex-col gap-1 rounded-[16px] justify-center items-center w-[410px] h-[240px]">
            <div>
              <img src={iconPeople} alt="permanent-icon" />
            </div>
            <div className="text-blue text-xl font-semibold text-center  hellix-font">
              Supporting Role:
            </div>
            <div className="text-blue text-xl font-normal text-center hellix-font">
              Guardians can ensure the student's mental, physical, and emotional
              well-being in a foreign country. 
            </div>
          </div>
        </div>
      </div>
      <div className="container max-w-1440px  mx-auto px-4   lg:px-14">
        <div style={{ border: "1px solid #cde1ea" }}></div>
      </div>
      {/* Application  */}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={ellipticalIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl  text-blue font-semibold hellix-font">
            Application Process
          </div>
        </div>
        <div className=" lg:ml-8 hellix-font mt-3">
          <div className="flex gap-2">
            <div className=" hellix-font text-lg lg:text-[22px] font-semibold text-blue">
              Step 1:
            </div>
            <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue">
              Secure relevant documentation, including proof of relationship
              with the student and sufficient funds. {" "}
            </div>
          </div>
          <div className="flex gap-2 mt-2">
            <div className=" hellix-font text-lg lg:text-[22px]  font-semibold text-blue">
              Step 2:
            </div>
            <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue">
              Complete and submit the online visa application. 
            </div>
          </div>
          <div className="flex gap-2 mt-2">
            <div className=" hellix-font text-lg lg:text-[22px]  font-semibold text-blue">
              Step 3:
            </div>
            <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue">
              {" "}
              Undergo medical checks if requested. 
            </div>
          </div>
          <div className="flex gap-2 mt-2">
            <div className=" hellix-font text-lg lg:text-[22px]  font-semibold text-blue">
              Step 4:
            </div>
            <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue">
              Await the outcome of your visa application. 
            </div>
          </div>
        </div>
      </div>

      <div className="container max-w-1440px  mx-auto px-4   lg:px-14">
        <div style={{ border: "1px solid #cde1ea" }}></div>
      </div>
      {/* Why Choose Immiget Global?  */}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10  lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={helpIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            How Immiget Will Help You?
          </div>
        </div>

        <div className="xl:flex gap-6 mt-4">
          <div className="xl:w-[50%]">
            <img src={guardianImage} alt="helpIcon" className="h-[100%] " />
          </div>
          <div className="xl:w-[50%] max-xl:mt-4 flex flex-col gap-1 ">
            <div className="text-blue font-normal text-[22px]">
              At Immiget Global, we understand the importance of support for
              international students and their guardians. Our team of
              experienced consultants provides tailored advice, ensuring that
              all requirements are met for a smooth and stress-free visa
              application. From gathering documents to application submissions,
              Immiget Global is committed to making your journey to Australia
              seamless and secure. 
            </div>
            <div className="text-blue mt-2 font-normal text-[22px]">
              For any questions or to begin your application, contact us today
              and let us assist you in every step of the process. 
            </div>
          </div>
        </div>
      </div>

      {/*FAQ*/}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10  lg:px-14">
        <div className="lg:text-[38px] text-2xl text-center text-blue font-semibold hellix-font">
          Subclass 590 Student Guardian Visa FAQs
        </div>
        <div className="text-blue mt-3 font-normal text-xl lg:text-[22px] text-center  hellix-font">
          Our migration agents are experts in navigating complex processes,
          offering tailored solutions with professionalism and care. Trust us to
          provide transparent, efficient, and reliable support for a seamless
          journey to your new destination.
        </div>

        <div className="accordion-container mt-8">
          {/* Accordion Section 1 */}
          <div className="accordion">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(1)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                1. How long can I stay in Australia on a Subclass 500 Visa? 
              </h2>
              {activeIndex === 1 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 1 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  No, the Subclass 590 Visa does not permit guardians to work in
                  Australia. 
                </p>
              </div>
            )}
          </div>

          {/* Accordion Section 2 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(2)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                2. Can my other children accompany me on the Subclass 590 Visa? 
              </h2>
              {activeIndex === 2 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 2 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  No, the Subclass 590 Visa does not permit guardians to work in
                  Australia. 
                </p>
              </div>
            )}
          </div>

          {/* Accordion Section 3 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(3)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                3. How long can I stay in Australia on a Subclass 590 Visa? 
              </h2>
              {activeIndex === 3 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 3 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  No, the Subclass 590 Visa does not permit guardians to work in
                  Australia. 
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 4 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(4)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                4. Can I study in Australia on a Subclass 590 Visa? 
              </h2>
              {activeIndex === 4 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 4 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  No, the Subclass 590 Visa does not permit guardians to work in
                  Australia. 
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(5)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                5. Is it possible to switch to another visa type while on a
                Subclass 590 Visa? 
              </h2>
              {activeIndex === 5 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 5 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  No, the Subclass 590 Visa does not permit guardians to work in
                  Australia. 
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <Contact />
    </div>
  );
};

export default StudentGuardianVisa;

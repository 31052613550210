import React, { useState } from "react";
import Contact from "../contact";
import arrowIcon from "../../images/home/arrow-icon.svg";
import ellipticalIcon from "../../images/individual/ellipticalIcon.svg";
import businessVisitor from "../../images/individual/BusinessVisitorStream.jpg";
import touristPlace from "../../images/individual/turiest-visa.png";
import familyVisitor from "../../images/individual/SponserFamilyStream.jpg";
import toggleIcon from "../../images/individual/fa-solid_stream.svg";
import statusStream from "../../images/individual/ApprovedestinatationStream.jpg";
import rightVector from "../../images/individual/right-vector.svg";
import immigetGlobal from "../../images/individual/immiget-global.png";
import applicationProcess from "../../images/individual/application-proccess.svg";
import visitorPlace from "../../images/individual/SC600VistitorVisa.jpg";
import helpIcon from "../../images/individual/helpIcon.svg";
import { FaPlus, FaTimes } from "react-icons/fa";
import { Helmet } from "react-helmet-async";

const VisitorVisa = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div>
      <Helmet>
        <title>
          {" "}
          Visit Australia with Ease – Immiget Global Australia Experts
        </title>
        <meta
          name="description"
          content="Immiget Global Australia helps you secure a Subclass 600 visa hassle-free. Expert visitor visa consultants for a smooth process."
        />
      </Helmet>
      <div className="w-full h-90 font-Hellix bg-cover bg-center bg-image">
        <div className="max-w-[1440px] px-4 md:px-[75px] m-auto h-full">
          <div className="flex flex-col gap-2 md:gap-2 pt-10 md:pt-20 pb-[90px] h-full justify-center">
            <div className="text-white  hellix-font  text-center font-semibold text-[22px] md:text-[54px]">
              Subclass 600 Visitor Visa
            </div>
            <div className="flex justify-center  gap-2 md:gap-4">
              <div className="text-white  hellix-font text-[10px]  lg:text-sm flex items-center md:text-lg font-medium">
                HOME
              </div>
              <div className="flex items-center">
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
              </div>
              <div className="text-white hellix-font text-[10px]  lg:text-sm flex items-center md:text-lg font-medium uppercase">
                Individual & Families
              </div>
              <div className="flex items-center">
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
              </div>
              <div className="text-white hellix-font  text-[10px]  lg:text-sm flex items-center md:text-lg font-medium uppercase">
                Subclass 600 Visitor Visa
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Subclass 600 Visitor Visa*/}
      <div className="xl:flex gap-4 justify-center container max-w-1440px  mx-auto px-4  py-4 md:py-10 lg:px-14">
        <div className="xl:w-[60%] ">
          <div className="xl:text-[38px] text-[24px] max-xl:text-center text-blue mt-6 font-semibold hellix-font">
            What Is the Subclass 600 Visitor Visa?
          </div>
          <div className=" max-xl:text-center hellix-font text-blue text-lg xl:text-[22px] font-normal ">
            The Subclass 600 Visitor Visa is designed to allow individuals to
            visit Australia for tourism, business activities, or to visit
            family. This visa offers a flexible and temporary stay, ranging from
            a few months to a year, based on the applicant’s intended purpose
            and specific stream requirements. At Immiget Global, we specialize
            in helping clients navigate the complexities of the Subclass 600
            visa application process, providing tailored legal guidance to
            maximize the chances of a successful outcome.
          </div>
        </div>
        <div className="xl:w-[40%] max-xl:justify-center max-xl:flex max-xl:mt-4">
          <img src={visitorPlace} alt="banner-image" />
        </div>
      </div>

      {/* Streams of the Subclass 600 Visitor Visa   */}
      <div className="container max-w-1440px mx-auto px-4 py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={toggleIcon} alt="toggle Icon" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            Types of Streams Under the Subclass 600 Visa
          </div>
        </div>
        <div className="text-blue font-normal text-lg lg:text-[22px] my-2 hellix-font">
          The Subclass 600 visa offers multiple streams, each suited to
          different purposes and needs. These streams include:
        </div>
        <div className="xl:flex gap-4 justify-center">
          <div className="xl:w-[70%]">
            <div className="text-blue font-medium text-xl lg:text-[28px] my-2 hellix-font">
              1. Tourist Stream
            </div>
            <div>
              <ul className="list-inside">
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal">
                  <span className="text-blue">•</span>
                  <div>
                    The Tourist Stream of the Subclass 600 Visa is ideal for
                    individuals seeking to visit Australia solely for tourism or
                    recreation.
                  </div>
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold mt-2">
                  <span className="text-blue">•</span>
                  <div>
                    Duration:{" "}
                    <span className="font-normal">
                      Typically allows stays of three, six, or twelve months,
                      depending on the applicant’s purpose and profile.
                    </span>
                  </div>
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold mt-2">
                  <span className="text-blue">•</span>
                  <div>
                    Eligibility:{" "}
                    <span className="font-normal">
                      Applicants must demonstrate sufficient funds, a genuine
                      intention to visit temporarily, and a strong incentive to
                      return to their home country.
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="xl:w-[30%]">
            <img src={touristPlace} alt="Tourist Place" />
          </div>
        </div>
      </div>

      <div className="container max-w-1440px mx-auto px-4 py-6 xl:py-10 lg:px-14">
        <div className="xl:flex gap-4 justify-center">
          <div className="xl:w-[40%]">
            <img src={businessVisitor} alt="Business Visitor" />
          </div>
          <div className="xl:w-[60%]">
            <div className="text-blue font-medium text-xl lg:text-[28px] my-2 hellix-font">
              2. Business Visitor Stream
            </div>
            <div>
              <ul className="list-inside">
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal">
                  <span className="text-blue">•</span>
                  <div>
                    The Business Visitor Stream is designed for short-term
                    business activities such as attending conferences, business
                    meetings, or engaging in negotiations. This stream does not
                    permit working or offering paid services in Australia.
                  </div>
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold mt-2">
                  <span className="text-blue">•</span>
                  <div>
                    Duration:{" "}
                    <span className="font-normal">
                      Generally granted for up to three months.
                    </span>
                  </div>
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold mt-2">
                  <span className="text-blue">•</span>
                  <div>
                    Eligibility:{" "}
                    <span className="font-normal">
                      Requires proof of business activities and sufficient funds
                      for the intended duration of stay, along with evidence of
                      ties to one’s home country or employer.
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className=" container max-w-1440px  mx-auto px-4 py-6 xl:py-10 lg:px-14">
        <div className="xl:flex gap-4 justify-center">
          <div className="xl:w-[60%]">
            <div className="text-blue font-medium text-xl lg:text-[28px] my-2 hellix-font">
              3. Sponsored Family Stream
            </div>
            <div>
              <ul className="list-inside">
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal">
                  <span className="text-blue text-xl lg:text-[22px]">•</span>
                  <div>
                    The Sponsored Family Stream allows applicants to visit their
                    family members residing in Australia. This stream requires
                    sponsorship by an eligible family member who is a permanent
                    resident or citizen of Australia.
                  </div>
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold mt-2">
                  <span className="text-blue text-xl lg:text-[22px]">•</span>
                  <div>
                    Duration:{" "}
                    <span className="font-normal">
                      The stay period varies and is often granted for three,
                      six, or twelve months, with specific conditions attached.
                    </span>
                  </div>
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold mt-2">
                  <span className="text-blue text-xl lg:text-[22px]">•</span>
                  <div>
                    Eligibility:{" "}
                    <span className="font-normal">
                      Requires sponsorship, an assurance of support, and
                      evidence of a strong incentive to return home upon visa
                      expiration.
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="xl:w-[40%]">
            <img src={familyVisitor} alt="Family Visitor" />
          </div>
        </div>
      </div>

      <div className=" container max-w-1440px  mx-auto px-4 py-6 xl:py-10 lg:px-14">
        <div className="xl:flex gap-4 justify-center">
          <div className="xl:w-[40%]">
            <img src={statusStream} alt="Status Stream" />
          </div>

          <div className="xl:w-[60%]">
            <div className="text-blue font-medium text-xl lg:text-[28px] my-2 hellix-font">
              4. Approved Destination Status Stream
            </div>
            <div>
              <ul className="list-inside">
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal">
                  <span className="text-blue text-xl lg:text-[22px]">•</span>
                  <div>
                    Specifically available to citizens of the People’s Republic
                    of China, this stream allows Chinese nationals to visit
                    Australia as part of a tour group.
                  </div>
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold mt-2">
                  <span className="text-blue text-xl lg:text-[22px]">•</span>
                  <div>
                    Duration:{" "}
                    <span className="font-normal">
                      Typically issued for a short stay, often aligned with the
                      tour schedule.
                    </span>
                  </div>
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold mt-2">
                  <span className="text-blue text-xl lg:text-[22px]">•</span>
                  <div>
                    Eligibility:{" "}
                    <span className="font-normal">
                      Requires applicants to travel with an approved tour group,
                      ensuring compliance with travel terms.
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Eligibility Requirements for the Subclass 600 Visa   */}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={ellipticalIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl  text-blue font-semibold hellix-font">
            Who Is Eligible for the Subclass 600 Visitor Visa?
          </div>
        </div>
        <div className="lg:text-[22px] text-lg  text-blue font-normal hellix-font py-2">
          Eligibility for the Subclass 600 Visitor Visa varies by stream, but
          common requirements across streams include: 
        </div>

        <div className="lg:ml-4 mt-2">
          <div className="flex gap-2">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              <span className="text-blue text-lg lg:text-[22px] font-semibold hellix-font">
                Proof of Financial Stability:
              </span>
              Applicants must demonstrate they have sufficient funds to support
              themselves throughout their stay in Australia. This includes
              documentation of bank statements, income sources, and any support
              from sponsors. 
            </div>
          </div>
          <div className="flex gap-2 my-2">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              <span className="text-blue text-lg lg:text-[22px] font-semibold hellix-font">
                Health and Character Requirements:
              </span>{" "}
              All Subclass 600 applicants must meet Australia’s health and
              character standards, which may involve health assessments and
              police clearances depending on the applicant’s profile and travel
              history. 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              <span className="text-blue text-lg lg:text-[22px] font-semibold hellix-font">
                Health and Character Requirements:
              </span>{" "}
              All Subclass 600 applicants must meet Australia’s health and
              character standards, which may involve health assessments and
              police clearances depending on the applicant’s profile and travel
              history. 
            </div>
          </div>
        </div>
      </div>

      {/* Application Process   */}
      <div className=" container max-w-1440px  mx-auto px-4  lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={applicationProcess} alt=" Application Process" />
          </div>
          <div className="lg:text-[32px] text-2xl  text-blue font-semibold hellix-font">
            Step-by-Step Guide to the Application Process
          </div>
        </div>
        <div className=" lg:ml-8 hellix-font mt-3">
          <div className=" hellix-font text-lg lg:text-[22px] font-semibold text-blue">
            Step 1: Preparation of Documentation 
          </div>
          <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue py-2">
            The initial stage involves gathering documentation, which includes
            financial statements, invitation letters (for the Sponsored Family
            Stream), business invitations (for the Business Stream), and
            evidence supporting the GTE requirement.
          </div>

          <div className=" hellix-font text-lg lg:text-[22px]  font-semibold text-blue">
            Step 2: Application Submission
          </div>
          <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue py-2">
            All applications are submitted online, and applicants must ensure
            that each stream's unique requirements are fulfilled. Immiget Global
            meticulously reviews each document to guarantee a complete,
            compliant submission. 
          </div>

          <div className=" hellix-font text-lg lg:text-[22px]  font-semibold text-blue">
            Step 3: Additional Information or Interview Requests 
          </div>
          <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue py-2">
            {" "}
            In some cases, the Department of Home Affairs may request additional
            information or an interview to clarify the applicant’s intentions.
            Our legal team prepares applicants for potential questions and
            assists in providing any supplementary documents. 
          </div>

          <div className=" hellix-font text-lg lg:text-[22px]  font-semibold text-blue">
             Step 4: Visa Decision
          </div>
          <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue py-2">
            Once the application is processed, a decision is provided by the
            Department. Upon approval, visa holders must adhere to the
            conditions stipulated, including restrictions on work or study. 
          </div>
        </div>
        <div className="text-blue text-[22px] lg:text-[25px] font-semibold hellix-font mt-4">
          Conditions and Limitations of the Subclass 600 Visa 
        </div>
        <div className="text-blue text-xl lg:text-[22px] font-normal hellix-font mt-4">
          The Subclass 600 Visitor Visa comes with specific conditions,
          particularly regarding employment and length of stay: 
        </div>
        <div>
          <ul className="lg:ml-4 list-inside pt-2">
            <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal">
              <span className="text-blue text-xl lg:text-[22px] hellix-font font-semibold">
                •
              </span>
              <div>
                <span className="text-blue text-xl lg:text-[22px] hellix-font font-semibold">
                  Work Prohibition:
                </span>
                This visa strictly prohibits any form of employment in
                Australia. The Business Visitor Stream allows certain business
                activities but does not permit hands-on work or payment for
                services.
              </div>
            </li>
            <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal py-2">
              <span className="text-blue text-xl lg:text-[22px] hellix-font font-semibold">
                •
              </span>
              <div>
                <span className="text-blue text-xl lg:text-[22px] hellix-font font-semibold">
                  Limited Study Opportunities:
                </span>
                Study is typically restricted to courses of up to three months
                unless specified otherwise.
              </div>
            </li>
            <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal">
              <span className="text-blue text-xl lg:text-[22px] hellix-font font-semibold">
                •
              </span>
              <div>
                <span className="text-blue text-xl lg:text-[22px] hellix-font font-semibold">
                  Stay Duration Compliance:
                </span>
                Visa holders must leave Australia by the visa's expiration date
                and abide by any conditions attached to their visa, including
                limitations on multiple entries if the visa is granted as a
                single-entry permit.
              </div>
            </li>
          </ul>
        </div>
      </div>

      {/* Immiget Global Assistance:   */}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={ellipticalIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl  text-blue font-semibold hellix-font">
            Why Choose Immiget Global for Your Visa Needs? 
          </div>
        </div>
        <div className="text-blue font-normal text-lg lg:text-[22px] mt-2 hellix-font">
          Our experienced migration lawyers provide expert guidance on:  {" "}
        </div>
        <div className="lg:ml-4 mt-4">
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              Eligibility assessments 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px]  font-normal hellix-font">
              Document preparation  {" "}
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px]  font-normal hellix-font">
              Application submission 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px]  font-normal hellix-font">
              Visa processing and compliance 
            </div>
          </div>
          <div className="text-blue font-normal text-lg lg:text-[22px] mt-2 hellix-font">
            Contact Immiget Global to discuss your Employer Nomination Scheme
            (ENS) Visa (Subclass 186) application. {" "}
          </div>
        </div>
      </div>
      {/* How Immiget Global Can Assist with Your Subclass 600 Application */}
      <div className="container max-w-1440px mx-auto px-4 py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={helpIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            How Immiget Global Can Assist with Your Subclass 600 Application
          </div>
        </div>
        <div className="text-blue font-normal text-lg lg:text-[22px] mt-2 hellix-font">
          The Subclass 600 Visitor Visa application process is thorough, and
          even minor inconsistencies can lead to refusals. Immiget Global’s
          migration specialists provide an exceptional level of service to
          ensure your application meets all Department of Home Affairs
          requirements:
        </div>
        <div className="xl:flex gap-6 mt-4">
          <div className="xl:w-[50%]">
            <img
              src={immigetGlobal}
              alt="Immiget Global"
              className="h-[100%]"
            />
          </div>
          <div className="xl:w-[50%] max-xl:mt-4 flex flex-col gap-4">
            <ul className="list-inside">
              <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold">
                <span>&bull;</span>
                <div>
                  Personalized Eligibility Assessment:{" "}
                  <span className="font-normal">
                    We conduct a detailed eligibility review for each applicant,
                    matching their needs with the most suitable stream and
                    identifying any potential issues early.
                  </span>
                </div>
              </li>
              <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold">
                <span>&bull;</span>
                <div>
                  Professional Documentation Support:
                  <span className="font-normal">
                    Our team assists in gathering and preparing all necessary
                    documents, including financial records, invitation letters,
                    and business credentials.
                  </span>
                </div>
              </li>
              <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold">
                <span>&bull;</span>
                <div>
                  Strategic Guidance on the Genuine Temporary Entrant
                  Requirement:
                  <span className="font-normal">
                    Understanding that the GTE requirement is one of the most
                    critical elements of the Subclass 600 Visa, we offer
                    strategies for demonstrating strong ties to the home
                    country.
                  </span>
                </div>
              </li>
              <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold">
                <span>&bull;</span>
                <div>
                  Expert Handling of Complex Cases:
                  <span className="font-normal">
                    For applicants who may have prior refusals or unique
                    circumstances, we provide targeted solutions to improve
                    their chances of a favorable outcome.
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="lg:text-[28px] text-2xl text-blue font-semibold hellix-font pt-4">
          Reach Out to Immiget Global Today 
        </div>
        <div className="lg:text-[22px] text-xl text-blue font-normal hellix-font pt-2">
          Whether you are visiting Australia for tourism, family, or business,
          the Subclass 600 Visitor Visa offers flexible options for short-term
          stays. At Immiget Global, our team brings the legal insight and
          meticulous preparation needed to make your application stand out.
          Contact us today for a consultation and take the first step toward a
          successful application for your Australian visit. 
        </div>
      </div>

      {/*FAQ*/}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10  lg:px-14">
        <div className="lg:text-[38px] text-2xl text-center text-blue font-semibold hellix-font">
          Frequently Asked Questions – Subclass 600 Visitor Visa
        </div>
        <div className="text-blue font-normal text-xl lg:text-[22px] text-center  hellix-font">
          Our migration agents are experts in navigating complex processes,
          offering tailored solutions with professionalism and care. Trust us to
          provide transparent, efficient, and reliable support for a seamless
          journey to your new destination.
        </div>

        <div className="accordion-container mt-8">
          {/* Accordion Section 1 */}
          <div className="accordion">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(1)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                1. Need a Visitor Visa? Trusted Subclass 600 Consultants in
                Australia
              </h2>
              {activeIndex === 1 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 1 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  A temporary visa for tourism, business, or other purposes in
                  Australia.
                </p>
              </div>
            )}
          </div>

          {/* Accordion Section 2 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(2)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                2. What is the validity period of the Subclass 600 visa? 
              </h2>
              {activeIndex === 2 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 2 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Varies (typically 3, 6, or 12 months).
                </p>
              </div>
            )}
          </div>

          {/* Accordion Section 3 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(3)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                3. Who is eligible for the Subclass 600 visa? 
              </h2>
              {activeIndex === 3 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 3 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Individuals with a genuine intention to stay temporarily.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 4 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(4)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                4. How do I apply for the Subclass 600 visa? 
              </h2>
              {activeIndex === 4 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 4 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Submit online application with required documents.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(5)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                5. What documents are required for the Subclass 600 visa? 
              </h2>
              {activeIndex === 5 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 5 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Passport, health insurance, proof of funds.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 6 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(6)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                6. How long does the Subclass 600 visa application process
                take? 
              </h2>
              {activeIndex === 4 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 6 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Typically 1-4 weeks.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 7 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(7)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                7. What are the benefits of the Subclass 600 visa? 
              </h2>
              {activeIndex === 7 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 7 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Temporary residence, tourism, business.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 8 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(8)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                8. What streams are available under the Subclass 600 visa? 
              </h2>
              {activeIndex === 8 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 8 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lgfont-normal hellix-font">
                  Tourism, Business, Sponsored Family, Approved Destination
                  Status.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 9 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(9)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                9. What health and character requirements apply to the Subclass
                600 visa? 
              </h2>
              {activeIndex === 9 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 9 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Standard health and character requirements.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 10 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(10)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                10. How can Immiget Global assist with my Subclass 600 visa
                application? 
              </h2>
              {activeIndex === 10 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 10 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Expert guidance on eligibility, document preparation.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <Contact />
    </div>
  );
};

export default VisitorVisa;

import React from "react";
import womenWorking from "../images/home/Who We Are-.jpg";
import foundPrinciple from "../images/home/Our Founding Principle.jpg";
import flowerWhite from "../images/home/white-flower.png";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <>
      <div className="flex justify-center items-center pb-2 lg:pb-10 mx-14  mt-12">
        <div className="w-2 h-2 bg-blue rotate-45">.</div>
        <div className=" w-[240px]  lg:w-[1300px] h-[2px] bg-blue"></div>
        <div className="w-2 h-2 bg-blue rotate-45">.</div>
      </div>

      <div className="">
        <div className="container max-w-[1440px] mx-auto py-2  lg:py-10 px-4 sm:px-6 lg:px-14">
          <div className="flex flex-col xl:flex-row justify-between items-stretch gap-6">
            <div className="hellix-font  lg:hidden text-[42px]  text-center  leading-[35px] font-semibold text-blue">
              About Immiget Global – Your Migration Experts
            </div>
            {/* Left Image */}
            <div className="flex-1 container max-w-[430px] h-[580px]">
              <img
                src={womenWorking}
                alt="Women Working"
                className="h-full object-cover rounded-[14px]"
              />
            </div>

            {/* Right Content */}
            <div className="flex-1 flex flex-col gap-6 justify-between">
              {/* Who We Are Section */}
              <div className="flex flex-col xl:flex-row items-center justify-between gap-4">
                <div className="lg:max-w-[615px]">
                  <div className="hellix-font hidden lg:block text-[24px] sm:text-[28px] lg:text-[42px] leading-[35px] font-medium text-blue">
                    About Immiget Global – Your Migration Experts
                  </div>
                  <div className="max-w-full sm:max-w-[615px] max-lg:text-center hellix-font text-[14px] sm:text-[16px] lg:text-[19px] font-light text-blue  mt-4">
                    <span className="hellix-font text-[16px]  sm:text-[18px] lg:text-[19px] font-semibold text-blue ">
                      Immiget Global was established in 2020
                    </span>{" "}
                    with a singular purpose: to bring order and stability to the
                    increasingly complex migration landscape. As a leading
                    migration consultancy, we are committed to providing expert
                    guidance and personalized solutions to individuals and
                    families navigating the intricate process of global
                    migration.
                  </div>
                </div>
                <button className=" mt-2 lg:mt-4 hellix-font text-[14px] sm:text-[15px] font-bold text-white  bg-blue   rounded-[10px] h-[44px] w-[202px] relative overflow-hidden group">
                  <span className="absolute top-0 left-0 w-full h-full bg-blue transform scale-x-0 group-hover:scale-x-100 transition-all duration-1000 ease-in-out origin-left"></span>
                  <span className="relative z-10 group-hover:text-white">
                    <Link to="/best-migration-australia-consultants"> Learn More</Link>
                  </span>
                </button>
              </div>

              {/* Founding Principle and Migration Solution Section */}
              <div className="flex flex-col cursor-pointer lg:flex-row bg-gradient-to-r to-[#91CBB0] via-[#5E9FA3] from-[#004B6B] lg:bg-white rounded-lg p-4 lg:h-[365px] items-stretch gap-6">
                {/* Founding Principle */}
                <Link to="/best-migration-australia-consultants">
                  <div className="flex-1 flex flex-col gap-3">
                    <div className="flex items-start justify-between">
                      <div className="hellix-font font-semibold text-[28px] leading-[49px] sm:text-[32px] lg:text-[42px] text-white ">
                        Our Core Values & Commitment
                      </div>

                      <div className="">
                        <img src={flowerWhite} alt="Flower Icon" />
                      </div>
                    </div>
                    <div className="max-w-full  hellix-font text-[14px] sm:text-[16px] lg:text-base text-white  font-normal ">
                      Our firm is built on three core{" "}
                      <span className="hellix-font text-[14px] sm:text-[16px] lg:text-base font-semibold text-white ">
                        principles: excellence, empathy, and integrity.
                      </span>{" "}
                      We strive for excellence in every aspect of our work,
                      delivering exceptional results through expert knowledge
                      and precision.
                    </div>
                    <div>
                      <img
                        src={foundPrinciple}
                        alt="Founding Principle"
                        className="w-full  md:h-[220px]"
                      />
                    </div>
                  </div>
                </Link>

                {/* Divider */}

                {/* Divider-Responsive 1024px*/}
                <div className="block lg:hidden w-full h-[1px] bg-[#0193D1]" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;

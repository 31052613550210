import React, { useState } from "react";
import { API_URL } from "../config/apiConfig";
import axios from "axios";
import { HiOutlineMailOpen, HiPhone } from "react-icons/hi";
import { Link } from "react-router-dom";
import { Mail, Phone } from "lucide-react";

const Contact = () => {
  // Initialize formData state
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState({ type: "", message: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      console.log("Sending request to:", `${API_URL}/send-email`);
      const response = await axios.post(`${API_URL}/send-email`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
        firstName: formData.firstName,
        lastName: formData.lastName,
        phoneNumber: formData.phoneNumber,
        email: formData.email,
        message: formData.message,
      });

      console.log("Email sent successfully:", response.data.message);
      setStatus({ type: "success", message: "Message sent successfully!" });

      // Clear form
      setFormData({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        message: "",
      });
    } catch (error) {
      console.error("Error sending email:", error);
      setStatus({
        type: "error",
        message: error.response?.data?.message || "Failed to send message",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container max-w-1440px mx-auto px-4 sm:px-8 py-10 lg:px-14">
      <div className="grid xl:grid-cols-2">
        {/* Left Section */}
        <div className="contact xl:pe-5">
          <div className="text-[38px] max-xl:text-center xl:text-[48px] text-blue hellix-font font-semibold">
            Get Expert Guidance
          </div>
          <div className="text-lg hellix-font sm:text-2xl mt-6 text-blue font-semibold">
            Email, Call or fill the form to reach us anytime!
          </div>
          <div className="text-lg hellix-font mt-2 xl:mt-4 text-blue font-normal">
            <Link
              to="#"
              className="flex items-center"
              onClick={(e) => {
                window.location.href = "mailto:connect@immigetglobal.com";
                e.preventDefault();
              }}
            >
              <span>
                <HiOutlineMailOpen className="left-4 text-blue md:w-[22px] md:h-[16px] w-[30px] h-[20px]" />
              </span>
              <span>connect@immigetglobal.com</span>
            </Link>
          </div>

          <div className="text-lg hellix-font mt-2 xl:mt-4 text-blue font-normal">
            <Link
              to="#"
              className="flex items-center"
              onClick={(e) => {
                window.location.href = "tel:+610370658787";
                e.preventDefault();
              }}
            >
              <span>
                <HiPhone className="left-4 text-blue md:w-[22px] md:h-[16px] w-[30px] h-[20px]" />
              </span>
              <span>+61 03 7065 8787</span>
            </Link>
          </div>
          <div className="max-w-[433px] hellix-font mt-2 font-medium text-lg sm:text-xl text-blue">
            Whatever your migration challenge is, a consultation with one of our
            migration agents will be a starting point of your success{" "}
          </div>

          <div className="py-4 flex flex-wrap relative rounded-lg w-full mt-8">
            <div className="absolute top-0 -z-0 w-full h-full hidden xl:block">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.820576329962!2d145.0096935766536!3d-37.81767143426264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad64250ddd169a5%3A0xe1e5458be0fe1021!2s147%20Blazey%20St%2C%20Richmond%20VIC%203121%2C%20Australia!5e0!3m2!1sen!2sin!4v1732642310825!5m2!1sen!2sin"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="w-full h-[450px]"
                alt=""
                title="map_one"
              />
            </div>

            {/* Content Over the Map */}
            <div
              className="rounded-s-lg truncate w-full z-10 text-white p-6 xl:absolute xl:w-[75%] xl:h-[450px] top-0 flex flex-col gap-4 xl:gap-8 my-auto justify-center"
              style={{
                background:
                  "linear-gradient(127.59deg, rgba(226, 242, 235, 0.54) 90.72%, rgba(219, 247, 250, 0.54) 100%)",
                backdropFilter: "blur(26px)",
              }}
            >
              <div className="font-semibold hellix-font text-[42px] text-blue xl:pb-2">
                Our Location
              </div>
              <div className="font-medium hellix-font text-2xl text-blue">
                Connecting Near and Far
              </div>
              <div className="font-semibold hellix-font text-xl text-blue py-2 xl:py-2">
                HEADQUARTERS:
                <br />
                Suite 104/672 Glenferrie Road,
                <br />
                Hawthorn 3122,
                <br />
                Australia
              </div>
            </div>
            <div className="-z-0 w-full h-[] xl:hidden">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.820576329962!2d145.0096935766536!3d-37.81767143426264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad64250ddd169a5%3A0xe1e5458be0fe1021!2s147%20Blazey%20St%2C%20Richmond%20VIC%203121%2C%20Australia!5e0!3m2!1sen!2sin!4v1732642310825!5m2!1sen!2sin"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="w-full h-[250px] md:h-[400px]"
                alt=""
                title="map_two"
              />
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="xl:h-[786px] hellix-font bg-shape p-4 py-6 md:py-10 md:p-10 rounded-lg">
          <div className="md:text-left text-center mb-10">
            <div className="font-semibold hellix-font text-[32px] sm:text-[42px] text-blue">
              Get Expert Assistance – Let’s Talk!
            </div>
            <div className="font-medium hellix-font text-lg sm:text-2xl text-blue mt-2">
              You can reach us anytime
            </div>
          </div>

          {/* Form */}
          <form className="w-full space-y-4" onSubmit={handleSubmit}>
            {/* First and Last Name */}
            <div className="flex flex-col sm:flex-row gap-6">
              <input
                className="rounded-[14px] w-full h-[78px] bg-bginput text-lg font-medium text-[#8D8B8B] placeholder:text-[#8D8B8B] pl-4 focus:outline-none"
                name="firstName"
                type="text"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
              <input
                className="rounded-[14px] w-full h-[78px] bg-bginput text-lg font-medium text-[#8D8B8B] placeholder:text-[#8D8B8B] pl-4 focus:outline-none"
                name="lastName"
                type="text"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>

            {/* Phone Number */}
            <div className="relative w-full mb-4">
              <Phone className="absolute left-4 top-1/2 transform -translate-y-1/2 text-blue md:w-[22px] md:h-[16px] w-[30px] h-[20px]" />
              <input
                className="w-full pl-20 h-[78px] rounded-[14px] hellix-font bg-bginput text-lg font-medium text-[#8D8B8B] placeholder:text-[#8D8B8B] focus:outline-none"
                name="phoneNumber"
                placeholder="Phone Number"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
              />
            </div>

            {/* Email */}
            <div className="relative w-full">
              <Mail className="absolute left-4 top-1/2 transform -translate-y-1/2 text-blue md:w-[22px] md:h-[16px] w-[30px] h-[20px]" />
              <input
                className="w-full pl-12 pr-4 h-[78px] hellix-font rounded-[14px] bg-bginput text-lg font-medium text-[#8D8B8B] placeholder:text-[#8D8B8B] focus:outline-none"
                name="email"
                type="email"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            {/* Message */}
            <div>
              <textarea
                className="rounded-[14px] hellix-font w-full h-[140px] bg-bginput text-lg font-medium text-[#8D8B8B] placeholder:text-[#8D8B8B] p-4 resize-none focus:outline-none"
                name="message"
                placeholder="How Can We Help?"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>

            {/* Submit Button */}
            <div className="text-center">
              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full mt-2 hellix-font bg-background text-white text-lg sm:text-2xl font-medium h-[78px] rounded-[14px]"
              >
                {isSubmitting ? "Sending..." : "Submit"}
              </button>
            </div>

            {/* Status Messages */}
            {status.type === "success" && (
              <div className="text-green-600 text-center mt-4">
                {status.message}
              </div>
            )}
            {status.type === "error" && (
              <div className="text-red-600 text-center mt-4">
                {status.message}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;

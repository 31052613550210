import React, { useState } from "react";
import Contact from "../contact";
import arrowIcon from "../../images/home/arrow-icon.svg";
import ellipticalIcon from "../../images/individual/ellipticalIcon.svg";
import postStusyWork from "../../images/individual/Post Study Stream.jpg";
import graduatePlace from "../../images/individual/Gradute Work Stream.jpg";
import toggleIcon from "../../images/individual/fa-solid_stream.svg";
import rightVector from "../../images/individual/right-vector.svg";
import immigetGlobal from "../../images/individual/immiget-global.png";
import applicationProcess from "../../images/individual/application-proccess.svg";
import temporaryGraduate from "../../images/individual/GraduateVisa485.jpg";
import helpIcon from "../../images/individual/helpIcon.svg";
import { FaPlus, FaTimes } from "react-icons/fa";
import benifitIcon from "../../images/individual/benifitIcon.png";
import permanentIcon from "../../images/individual/permanent-resident.svg";
import unlimitedIcon from "../../images/individual/ic_outline-home-work.svg";
import iconPeople from "../../images/individual/akar-icons_people-group.svg";
import { Helmet } from "react-helmet-async";

const TemporaryGraduateVisa = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div>
      <Helmet>
        <title> Subclass 485 Visa Experts – Immiget Global Australia</title>
        <meta
          name="description"
          content="Looking for a Subclass 485 visa? Immiget Global Australia offers expert consultation for Temporary Graduate Visas & post-study work options."
        />
      </Helmet>
      <div className="w-full h-90 font-Hellix bg-cover bg-center bg-image">
        <div className="max-w-[1440px] px-4 md:px-[75px] m-auto h-full">
          <div className="flex flex-col gap-2 md:gap-2 pt-10 md:pt-20 pb-[90px] h-full justify-center">
            <div className="text-white  hellix-font  text-center font-semibold text-[22px] md:text-[54px]">
              Subclass 485 (Temporary Graduate Visa)
            </div>
            <div className="flex justify-center  gap-2 md:gap-4">
              <div className="text-white  hellix-font text-[10px]  lg:text-sm flex items-center md:text-lg font-medium">
                HOME
              </div>
              <div className="flex items-center">
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
              </div>
              <div className="text-white hellix-font text-[10px]  lg:text-sm flex items-center md:text-lg font-medium uppercase">
                Individual & Families
              </div>
              <div className="flex items-center">
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
              </div>
              <div className="text-white hellix-font  text-[10px]  lg:text-sm flex items-center md:text-lg font-medium uppercase">
                Subclass 485 (Temporary Graduate Visa)
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Subclass 600 Visitor Visa*/}
      <div className="xl:flex gap-4 justify-center container max-w-1440px  mx-auto px-4  py-4 md:py-10 lg:px-14">
        <div className="xl:w-[60%] ">
          <div className="xl:text-[38px] text-[24px] max-xl:text-center text-blue mt-6 font-semibold hellix-font">
            What Is the Temporary Graduate Visa (Subclass 485)?
          </div>
          <div className=" max-xl:text-center hellix-font text-blue text-lg xl:text-[22px] font-normal ">
            The Subclass 485 Temporary Graduate Visa offers international
            students who have completed their studies in Australia a pathway to
            gain valuable work experience and potentially settle in Australia
            permanently. Designed to bridge the gap between academic
            accomplishment and skilled employment, this visa provides an ideal
            opportunity for recent graduates to contribute their skills to
            Australia’s workforce, enhancing their career prospects and
            eligibility for long-term residency. {" "}
          </div>
          <div className="mt-2  max-xl:text-center hellix-font text-blue text-lg xl:text-[22px] font-normal ">
            At Immiget Global, we specialize in managing the intricate
            application process for the Subclass 485 Visa, guiding clients
            through eligibility, documentation, and work rights to ensure they
            can make the most of this valuable post-study visa. {" "}
          </div>{" "}
        </div>
        <div className="xl:w-[40%] max-xl:justify-center max-xl:flex max-xl:mt-4">
          <img src={temporaryGraduate} alt="banner-image" />
        </div>
      </div>

      {/* Streams of the Subclass 600 Visitor Visa   */}
      <div className="container max-w-1440px mx-auto px-4 py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={toggleIcon} alt="toggle Icon" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            Streams under the Subclass 485 Visa 
          </div>
        </div>
        <div className="text-blue font-normal text-lg lg:text-[22px] my-2 hellix-font">
          The Subclass 485 Visa is divided into two distinct streams, each
          tailored to specific qualifications and career goals of recent
          graduates: 
        </div>
        <div className="xl:flex gap-4 justify-center">
          <div className="xl:w-[70%]">
            <div className="text-blue font-medium text-xl lg:text-[28px] my-2 hellix-font">
              1. Graduate Work Stream 
            </div>
            <div>
              <ul className="list-inside">
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal">
                  <span className="text-blue">•</span>
                  <div>
                    Designed for international students who graduate with skills
                    and qualifications aligned with high-demand occupations
                    listed on Australia’s Skilled Occupation List (SOL). 
                  </div>
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold mt-2">
                  <span className="text-blue">•</span>
                  <div>
                    Visa Duration:
                    <span className="font-normal">
                      Valid for up to 18 months, allowing graduates to gain work
                      experience and strengthen their professional profiles. 
                    </span>
                  </div>
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold mt-2">
                  <span className="text-blue">•</span>
                  <div>
                    Eligibility:{" "}
                    <span className="font-normal">
                      Applicants must have completed at least two years of study
                      in Australia in a field that matches an occupation on the
                      SOL, and possess skills suitable for the nominated
                      occupation. 
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="xl:w-[30%]">
            <img src={graduatePlace} alt="Tourist Place" />
          </div>
        </div>
      </div>

      <div className="container max-w-1440px mx-auto px-4 py-6 xl:py-10 lg:px-14">
        <div className="xl:flex gap-4 justify-center">
          <div className="xl:w-[40%]">
            <img src={postStusyWork} alt="Business Visitor" />
          </div>
          <div className="xl:w-[60%]">
            <div className="text-blue font-medium text-xl lg:text-[28px] my-2 hellix-font">
              2. Post-Study Work Stream 
            </div>
            <div>
              <ul className="list-inside">
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal">
                  <span className="text-blue">•</span>
                  <div>
                    Available to international students who have completed a
                    higher education degree, including bachelor’s, master’s, or
                    doctoral qualifications, from an Australian institution. 
                  </div>
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold mt-2">
                  <span className="text-blue">•</span>
                  <div>
                    Visa Duration:
                    <span className="font-normal">
                      Depending on the degree level, this visa is valid for two
                      to four years, with doctoral graduates receiving the
                      maximum term. 
                    </span>
                  </div>
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold mt-2">
                  <span className="text-blue">•</span>
                  <div>
                    Eligibility:{" "}
                    <span className="font-normal">
                      Primarily for those holding an Australian higher education
                      degree, regardless of occupation, as this stream is
                      focused on the general graduate cohort rather than the
                      occupation list. 
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Eligibility Requirements for the Subclass 600 Visa   */}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={ellipticalIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl  text-blue font-semibold hellix-font">
            Eligibility Requirements for Subclass 485 Visa 
          </div>
        </div>
        <div className="lg:text-[22px] text-lg  text-blue font-normal hellix-font py-2">
          The Subclass 485 Visa carries specific requirements that all
          applicants must meet, particularly regarding age, study duration, and
          visa status. A precise understanding of these requirements is crucial,
          as even minor discrepancies can result in delays or refusals. Immiget
          Global works with clients to ensure each condition is fully
          satisfied. 
        </div>

        <div className="lg:ml-4 mt-2">
          <div className="flex gap-2 mt-1">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              <span className="text-blue text-lg lg:text-[22px] font-semibold hellix-font">
                Age Limit:
              </span>
              Applicants must be under 50 years of age at the time of
              application. 
            </div>
          </div>
          <div className="flex gap-2 my-2 mt-1">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              <span className="text-blue text-lg lg:text-[22px] font-semibold hellix-font">
                English Language Proficiency:
              </span>{" "}
              Proof of adequate English language skills, typically demonstrated
              by a test score unless the applicant holds a passport from an
              exempt country. 
            </div>
          </div>
          <div className="flex gap-2 mt-1 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              <span className="text-blue text-lg lg:text-[22px] font-semibold hellix-font">
                Australian Study Requirement:
              </span>{" "}
              Applicants must meet the Australian Study Requirement, which
              stipulates at least two years of study in Australia in a
              registered course. 
            </div>
          </div>
          <div className="flex gap-2 mt-1">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              <span className="text-blue text-lg lg:text-[22px] font-semibold hellix-font">
                Recent Graduate:
              </span>{" "}
              The application must be lodged within six months of course
              completion to qualify as a recent graduate. 
            </div>
          </div>
          <div className="flex gap-2 mt-1">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              <span className="text-blue text-lg lg:text-[22px] font-semibold hellix-font">
                Health and Character Checks:
              </span>{" "}
              Standard health assessments and character evaluations are
              required, ensuring compliance with Australia’s immigration
              standards. 
            </div>
          </div>
          <div className="flex gap-2 mt-1">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              <span className="text-blue text-lg lg:text-[22px] font-semibold hellix-font">
                OSHC and Health Insurance:
              </span>{" "}
              Comprehensive health insurance coverage is required for the
              duration of the Subclass 485 Visa. 
            </div>
          </div>
        </div>
      </div>
      {/*Benefits  */}
      <div className=" container max-w-1440px  mx-auto px-4 py-6 xl:py-6  lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={benifitIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            Key Benefits of the Subclass 485 Visa 
          </div>
        </div>
        <div className="text-blue font-normal text-lg  lg:text-[22px] mt-2 hellix-font">
          Many Employer Sponsor Visa pathways offer options for permanent
          residency, making it a viable long-term settlement solution.
        </div>
        <div className="flex flex-wrap justify-between mt-8  ">
          <div className="shadow-custom-white  mt-4 flex flex-col gap-1  rounded-[16px] justify-center items-center w-[410px] h-[315px]">
            <div>
              <img src={permanentIcon} alt="permanent-icon" />
            </div>
            <div className="text-blue text-xl font-semibold text-center  hellix-font">
              Gaining Australian Work Experience
            </div>

            <div className="text-blue text-xl font-normal text-center  hellix-font">
              Provides a practical opportunity for recent graduates to gain
              valuable work experience within their field, enhancing career
              opportunities and residency eligibility. 
            </div>
          </div>
          <div className="shadow-custom-white  mt-4  flex flex-col gap-1 rounded-[16px] justify-center items-center w-[410px] h-[315px]">
            <div>
              <img src={unlimitedIcon} alt="permanent-icon" />
            </div>
            <div className="text-blue text-xl font-semibold text-center  hellix-font">
              Pathway to Permanent Residency
            </div>
            <div className="text-blue text-xl px-2 font-normal text-center hellix-font">
              Allows applicants to gain skills and work experience that can be
              used to apply for permanent residency through other skilled
              migration pathways, such as the Subclass 189 or 190 visas.{" "}
            </div>
          </div>
          <div className="shadow-custom-white mt-4  flex flex-col gap-1 rounded-[16px] justify-center items-center w-[410px] h-[315px]">
            <div>
              <img src={iconPeople} alt="permanent-icon" />
            </div>
            <div className="text-blue text-xl font-semibold text-center  hellix-font">
              Flexibility for Family Members
            </div>
            <div className="text-blue text-xl font-normal text-center hellix-font">
              Dependents can be included in the application, allowing them to
              live, work, or study in Australia, which is ideal for graduates
              seeking a comprehensive support system. {" "}
            </div>
          </div>
        </div>
      </div>
      {/* Application Process   */}
      <div className=" container max-w-1440px  mx-auto px-4 mt-4  lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={applicationProcess} alt=" Application Process" />
          </div>
          <div className="lg:text-[32px] text-2xl  text-blue font-semibold hellix-font">
            Application Process for the Subclass 485 Visa 
          </div>
        </div>
        <div className=" lg:ml-8 hellix-font mt-3 py-3">
          <div className=" hellix-font text-lg lg:text-[22px] font-semibold text-blue">
            Step 1: Preparation and Eligibility Assessment
          </div>
          <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue py-2">
            We conduct a detailed assessment of each applicant’s qualifications,
            study history, and career objectives to determine the appropriate
            stream and identify any potential barriers to eligibility. 
          </div>

          <div className=" hellix-font text-lg lg:text-[22px]  font-semibold text-blue">
            Step 2: Documentation and Submission 
          </div>
          <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue py-2">
            Our legal team assists with gathering essential documents, including
            academic transcripts, English language test results, health
            assessments, and character certifications. 
          </div>
          <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue py-2">
            We ensure that all documentation meets the Department of Home
            Affairs’ standards, reducing the risk of delays or refusals based on
            incomplete or incorrect
          </div>

          <div className=" hellix-font text-lg lg:text-[22px]  font-semibold text-blue">
            Step 3: Lodging the Application 
          </div>
          <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue py-2">
            {" "}
            Immiget Global handles the application lodgment process with the
            Department of Home Affairs, tracking progress and addressing any
            queries or additional requests from the Department. 
          </div>
          <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue py-2">
            {" "}
            We provide continuous updates to applicants, ensuring they remain
            informed throughout the processing period. 
          </div>

          <div className=" hellix-font text-lg lg:text-[22px]  font-semibold text-blue">
            Step 4: Post-Visa Support 
          </div>
          <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue py-2">
            Once the Subclass 485 Visa is granted, Immiget Global offers
            guidance on exploring job opportunities, building professional
            networks, and taking further steps towards permanent residency. 
          </div>
        </div>
      </div>

      {/* Immiget Global Assistance:   */}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={ellipticalIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl  text-blue font-semibold hellix-font">
            Immiget Global Assistance: 
          </div>
        </div>
        <div className="text-blue font-normal text-lg lg:text-[22px] mt-2 hellix-font">
          Our experienced migration lawyers provide expert guidance on: {" "}
        </div>
        <div className="lg:ml-4 mt-4">
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              Eligibility assessments 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px]  font-normal hellix-font">
              Document preparation 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px]  font-normal hellix-font">
              Application submission 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px]  font-normal hellix-font">
              Visa processing and compliance 
            </div>
          </div>
          <div className="text-blue font-normal text-lg lg:text-[22px] mt-2 hellix-font">
            Contact Immiget Global to discuss your Employer Nomination Scheme
            (ENS) Visa (Subclass 186) application. {" "}
          </div>
        </div>
      </div>
      {/* How Immiget Global Can Assist with Your Subclass 600 Application */}
      <div className="container max-w-1440px mx-auto px-4 py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={helpIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            Why Choose Immiget Global for Subclass 485 Visa Applications? 
          </div>
        </div>
        <div className="text-blue font-normal text-lg lg:text-[22px] mt-2 hellix-font">
          At Immiget Global, our migration professionals understand the nuances
          of the Subclass 485 Visa and are well-versed in helping clients
          transition smoothly from student life to working professionals in
          Australia. With our legal expertise, we are committed to securing the
          best possible outcome for graduates looking to advance their careers
          and secure their future in Australia. 
        </div>
        <div className="xl:flex gap-6 mt-4">
          <div className="xl:w-[50%]">
            <img
              src={immigetGlobal}
              alt="Immiget Global"
              className="h-[100%]"
            />
          </div>
          <div className="xl:w-[50%] max-xl:mt-4 flex flex-col gap-4">
            <ul className="list-inside">
              <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold">
                <span>&bull;</span>
                <div>
                  Personalized Eligibility Assessment:{" "}
                  <span className="font-normal">
                    We conduct a detailed eligibility review for each applicant,
                    matching their needs with the most suitable stream and
                    identifying any potential issues early.
                  </span>
                </div>
              </li>
              <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold">
                <span>&bull;</span>
                <div>
                  In-depth Knowledge of Migration Law:
                  <span className="font-normal">
                    Our team has a profound understanding of Australia’s
                    migration law and guides clients through each requirement
                    with precision and clarity. 
                  </span>
                </div>
              </li>
              <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold">
                <span>&bull;</span>
                <div>
                  Customized Application Strategy:
                  <span className="font-normal">
                    We offer tailored strategies for each client, ensuring that
                    every unique circumstance and career goal is fully
                    considered. 
                  </span>
                </div>
              </li>
              <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold">
                <span>&bull;</span>
                <div>
                  Responsive and Reliable Support:
                  <span className="font-normal">
                    From pre-application consultations to post-visa services, we
                    stand by our clients, providing reliable guidance and
                    dedicated support throughout their journey. 
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="lg:text-[28px] text-2xl text-blue font-semibold hellix-font pt-6">
          Begin Your Pathway with Immiget Global Today {" "}
        </div>
        <div className="lg:text-[22px] text-xl text-blue font-normal hellix-font pt-2">
          If you’re a recent graduate seeking to build a future in Australia,
          the Subclass 485 Visa offers an invaluable opportunity to gain
          professional experience and potentially achieve long-term residency.
          Contact Immiget Global today for a detailed consultation, and let us
          help you navigate this critical stage in your migration journey with
          expertise and confidence. 
        </div>
      </div>

      {/*FAQ*/}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10  lg:px-14">
        <div className="lg:text-[38px] text-2xl text-center text-blue font-semibold hellix-font">
          Subclass 485 (Temporary Graduate Visa) FAQs{" "}
        </div>
        <div className="text-blue font-normal text-xl lg:text-[22px] text-center  hellix-font">
          Our migration agents are experts in navigating complex processes,
          offering tailored solutions with professionalism and care. Trust us to
          provide transparent, efficient, and reliable support for a seamless
          journey to your new destination.
        </div>

        <div className="accordion-container mt-8">
          {/* Accordion Section 1 */}
          <div className="accordion">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(1)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                1. Recently Graduated? Apply for Your Subclass 485 Visa with
                Experts!
              </h2>
              {activeIndex === 1 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 1 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  A temporary visa for international students who have completed
                  studies in Australia.
                </p>
              </div>
            )}
          </div>

          {/* Accordion Section 2 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(2)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                2. What is the validity period of the Subclass 485 visa? {" "}
              </h2>
              {activeIndex === 2 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 2 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Up to 18 months (or 2-4 years for certain graduates).
                </p>
              </div>
            )}
          </div>

          {/* Accordion Section 3 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(3)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                3. Who is eligible for the Subclass 485 visa? {" "}
              </h2>
              {activeIndex === 3 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 3 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  International students who have completed an eligible
                  Australian qualification.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 4 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(4)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                4. How do I apply for the Subclass 485 visa? {" "}
              </h2>
              {activeIndex === 4 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 4 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Submit online application with required documents.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(5)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                5. What documents are required for the Subclass 485 visa? {" "}
              </h2>
              {activeIndex === 5 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 5 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Passport, qualification certificates, academic transcripts.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 6 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(6)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                6. How long does the Subclass 485 visa application process
                take? 
              </h2>
              {activeIndex === 4 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 6 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Typically 2-4 months.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 7 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(7)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                7. What are the benefits of the Subclass 485 visa? 
              </h2>
              {activeIndex === 7 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 7 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Temporary residence, work, and study in Australia.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 8 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(8)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                8. What streams are available under the Subclass 485 visa? 
              </h2>
              {activeIndex === 8 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 8 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lgfont-normal hellix-font">
                  Graduate Work Stream, Post-Study Work Stream.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 9 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(9)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                9. What health and character requirements apply to the Subclass
                485 visa? 
              </h2>
              {activeIndex === 9 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 9 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Standard health and character requirements.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 10 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(10)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                10. How can Immiget Global assist with my Subclass 485 visa
                application? 
              </h2>
              {activeIndex === 10 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 10 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Expert guidance on eligibility, document preparation.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <Contact />
    </div>
  );
};

export default TemporaryGraduateVisa;

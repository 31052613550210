import React, { useState } from "react";
import Contact from "../contact";
import arrowIcon from "../../images/home/arrow-icon.svg";
import ellipticalIcon from "../../images/individual/ellipticalIcon.svg";
import rightVector from "../../images/individual/right-vector.svg";
import benifitIcon from "../../images/individual/benifitIcon.png";
import permanentIcon from "../../images/individual/permanent-resident.svg";
import helpIcon from "../../images/individual/helpIcon.svg";
import { FaPlus, FaTimes } from "react-icons/fa";
import unlimitedIcon from "../../images/individual/ic_outline-home-work.svg";
import iconPeople from "../../images/individual/akar-icons_people-group.svg";
import workVisa from "../../images/individual/SC-400.jpg";
import guardianImage from "../../images/individual/guardian.png";
import { Helmet } from "react-helmet-async";

const EmployeeTemporaryVisa = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div>
      <Helmet>
        <title>Subclass 400 Visa Experts – Immiget Global Australia</title>
        <meta
          name="description"
          content="Immiget Global Australia provides expert guidance for Subclass 400 visas. Get a Temporary Work Visa in Australia for short-term skilled jobs."
        />
      </Helmet>
      <div className="w-full h-90 font-Hellix bg-cover bg-center bg-image">
        <div className="max-w-[1440px] px-4 md:px-[75px] m-auto h-full">
          <div className="flex flex-col gap-2 md:gap-2 pt-10 md:pt-20 pb-[90px] h-full justify-center">
            <div className="text-white  hellix-font  text-center font-semibold text-[22px] md:text-[54px]">
              Subclass 400 Temporary Work <br />
              (Short Stay Specialist) Visa
            </div>
            <div className="flex justify-center  gap-2 md:gap-4">
              <div className="text-white  hellix-font text-[10px]  lg:text-sm flex items-center md:text-lg font-medium">
                HOME
              </div>
              <div className="flex items-center">
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
              </div>
              <div className="text-white hellix-font text-[10px]  lg:text-sm flex items-center md:text-lg font-medium uppercase">
                Visas
              </div>
              <div className="flex items-center">
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
              </div>
              <div className="text-white hellix-font  text-[10px]  lg:text-sm flex items-center md:text-lg font-medium uppercase">
                Subclass 400 Temporary Work (Short Stay Specialist) Visa  
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Subclass 189 Visa: Skilled Independent Visa*/}
      <div className="xl:flex gap-4 justify-center container max-w-1440px  mx-auto px-4  py-4 md:py-10 lg:px-14">
        <div className="xl:w-[50%] ">
          <div className="xl:text-[38px] text-[24px] max-xl:text-center text-blue mt-6 font-semibold hellix-font">
            What Is the Subclass 400 Temporary Work Visa?  
          </div>
          <div className=" max-xl:text-center hellix-font text-blue text-lg xl:text-[22px] font-normal ">
            The Subclass 400 visa is a temporary visa for highly specialized
            workers to undertake short-term, high-priority work in Australia. {" "}
          </div>
        </div>
        <div className="xl:w-[50%] max-xl:justify-center max-xl:flex max-xl:mt-4">
          <img src={workVisa} alt="banner-image" />
        </div>
      </div>
      <div className="container max-w-1440px  mx-auto px-4   lg:px-14">
        <div style={{ border: "1px solid #cde1ea" }}></div>
      </div>
      {/*Benefits  */}
      <div className=" container max-w-1440px  mx-auto px-4 py-6 xl:py-6  lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={benifitIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            Key Features of the Short Stay Specialist Visa
          </div>
        </div>
        <div className="text-blue font-normal text-lg  lg:text-[22px] mt-2 hellix-font">
          Many Employer Sponsor Visa pathways offer options for permanent
          residency, making it a viable long-term settlement solution.
        </div>
        <div className="flex flex-wrap justify-between mt-8  ">
          <div className="shadow-custom-white  mt-4 flex flex-col gap-1  rounded-[16px] justify-center items-center w-[296px] h-[220px]">
            <div>
              <img src={permanentIcon} alt="permanent-icon" />
            </div>

            <div className="text-blue text-xl font-normal text-center  hellix-font">
              Requires evidence of specialized skills and experience
            </div>
          </div>
          <div className="shadow-custom-white  mt-4  flex flex-col gap-1 rounded-[16px] justify-center items-center w-[296px] h-[220px]">
            <div>
              <img src={unlimitedIcon} alt="permanent-icon" />
            </div>

            <div className="text-blue text-xl px-2 font-normal text-center hellix-font">
              R- Allows specialized workers to undertake short-term work in
              Australia{" "}
            </div>
          </div>
          <div className="shadow-custom-white mt-4  flex flex-col gap-1 rounded-[16px] justify-center items-center w-[296px] h-[220px]">
            <div>
              <img src={iconPeople} alt="permanent-icon" />
            </div>

            <div className="text-blue text-xl font-normal text-center hellix-font">
              No points test or sponsorship required{" "}
            </div>
          </div>
          <div className="shadow-custom-white mt-4  flex flex-col gap-1 rounded-[16px] justify-center items-center w-[296px] h-[220px]">
            <div>
              <img src={iconPeople} alt="permanent-icon" />
            </div>

            <div className="text-blue text-xl font-normal text-center hellix-font">
              Temporary visa with a validity period of up to 6 months (or 3
              months for highly specialized work){" "}
            </div>
          </div>
        </div>
      </div>
      <div className="container max-w-1440px  mx-auto px-4   lg:px-14">
        <div style={{ border: "1px solid #cde1ea" }}></div>
      </div>
      {/* Eligibility Criteria:  */}
      <div className=" container max-w-1440px  mx-auto px-4 py-6 xl:py-6 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={ellipticalIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px]  text-2xl text-blue font-semibold hellix-font">
            Eligibility Criteria:
          </div>
        </div>
        <div className=" ml-4 mt-3 text-blue text-lg xl:text-[22px] font-normal hellix-font">
          The Employer Sponsor Nomination Visa has requirements for both
          sponsoring employers and nominated workers:
        </div>

        <div className="lg:ml-4 mt-4">
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px] font-normal hellix-font">
              Highly specialized skills and experience s apply
            </div>
          </div>

          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              Invitation to work in Australia from an Australian organization {" "}
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              Evidence of financial support 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              Health and character requirements met {" "}
            </div>
          </div>
        </div>
      </div>

      {/* Eligibility Criteria:  */}
      <div className=" container max-w-1440px  mx-auto px-4 py-2 xl:py-4 lg:px-14">
        <div className=" ml-4  text-blue text-lg xl:text-[22px] font-semibold hellix-font">
          Eligible Activities: 
        </div>

        <div className="lg:ml-4 mt-4">
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px] font-normal hellix-font">
              Highly specialized work {" "}
            </div>
          </div>

          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              Intra-company transfers {" "}
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              Flight crew {" "}
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              Sporting events 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              Other short-term, high-priority activities  
            </div>
          </div>
        </div>
      </div>

      {/*Benefits  */}
      <div className=" container max-w-1440px  mx-auto px-4 py-6 xl:py-6  lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={benifitIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            Benefits of the Temporary Work Visa
          </div>
        </div>
        <div className="text-blue font-normal text-lg  lg:text-[22px] mt-2 hellix-font">
          Many Employer Sponsor Visa pathways offer options for permanent
          residency, making it a viable long-term settlement solution.
        </div>
        <div className="flex flex-wrap justify-between mt-8  ">
          <div className="shadow-custom-white  mt-4 flex flex-col gap-1  rounded-[16px] justify-center items-center w-[296px] h-[206px]">
            <div>
              <img src={permanentIcon} alt="permanent-icon" />
            </div>

            <div className="text-blue text-xl font-normal text-center  hellix-font">
              Temporary residence in Australia
            </div>
          </div>
          <div className="shadow-custom-white  mt-4  flex flex-col gap-1 rounded-[16px] justify-center items-center w-[296px] h-[206px]">
            <div>
              <img src={unlimitedIcon} alt="permanent-icon" />
            </div>

            <div className="text-blue text-xl px-2 font-normal text-center hellix-font">
              Undertake short-term, high-priority work{" "}
            </div>
          </div>
          <div className="shadow-custom-white mt-4  flex flex-col gap-1 rounded-[16px] justify-center items-center w-[296px] h-[206px]">
            <div>
              <img src={iconPeople} alt="permanent-icon" />
            </div>

            <div className="text-blue text-xl font-normal text-center hellix-font">
              No sponsorship or points test required{" "}
            </div>
          </div>
          <div className="shadow-custom-white mt-4  flex flex-col gap-1 rounded-[16px] justify-center items-center w-[296px] h-[206px]">
            <div>
              <img src={iconPeople} alt="permanent-icon" />
            </div>

            <div className="text-blue text-xl font-normal text-center hellix-font">
              Pathway to other temporary or permanent visas
            </div>
          </div>
        </div>
      </div>
      {/* Application  */}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={ellipticalIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl  text-blue font-semibold hellix-font">
            Application Process
          </div>
        </div>
        <div className=" lg:ml-8 hellix-font mt-3">
          <div className="flex gap-2">
            <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue">
              <span className="font-semibold">Step 1: </span> Employer submits a
              nomination application 
            </div>
          </div>
          <div className="flex gap-2 mt-2">
            <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue">
              <span className="font-semibold">Step 2: </span> Applicant submits
              a visa application
            </div>
          </div>
          <div className="flex gap-2 mt-2">
            <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue">
              {" "}
              <span className="font-semibold">Step 3: </span> Provide required
              documents and biometrics  
            </div>
          </div>
        </div>
      </div>

      <div className=" container max-w-1440px  mx-auto px-4 py-2 lg:px-14">
        <div className="lg:text-[32px]  text-2xl text-blue font-semibold hellix-font">
          Processing Times: 
        </div>
        <div>
          <ul class="lg:ml-4 list-inside">
            <li className="text-blue mt-2 text-xl lg:text-[22px] hellix-font font-normal">
              Typically 1-3 months, but can vary.
            </li>
          </ul>
        </div>
      </div>

      {/* Eligibility Criteria:  */}
      <div className=" container max-w-1440px  mx-auto px-4 py-2 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={ellipticalIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px]  text-2xl text-blue font-semibold hellix-font">
            How Immiget Global Supports Your Short-Term Work Visa  {" "}
          </div>
        </div>
        <div className=" ml-4 mt-3 text-blue text-lg xl:text-[22px] font-normal hellix-font">
          Our experienced migration lawyers provide expert guidance on:  {" "}
        </div>

        <div className="lg:ml-4 mt-4">
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px] font-normal hellix-font">
              Eligibility assessments 
            </div>
          </div>

          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              Document preparation 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              Application submission 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue  text-lg xl:text-[22px]  font-normal hellix-font">
              Compliance and regulations  
            </div>
          </div>
        </div>
        <div className="text-blue text-lg xl:text-[22px] mt-2  font-normal hellix-font">
          Contact Immiget Global to discuss your Subclass 400 Temporary Work
          (Short Stay Specialist) Visa application. {" "}
        </div>
      </div>
      <div className="container max-w-1440px  mx-auto px-4   lg:px-14">
        <div style={{ border: "1px solid #cde1ea" }}></div>
      </div>
      {/* Why Choose Immiget Global?  */}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10  lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={helpIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            Why Choose Immiget Global for Your Subclass 400 Visa?
          </div>
        </div>
        <div className="text-blue font-normal text-lg lg:text-[22px] mt-2 hellix-font">
          Immiget Global is here to assist at every stage, from sponsorship
          applications to final approvals. Our team provides:
        </div>
        <div className="xl:flex gap-6 mt-4">
          <div className="xl:w-[50%]">
            <img src={guardianImage} alt="helpIcon" className="h-[100%] " />
          </div>
          <div className="xl:w-[50%] max-xl:mt-4 flex flex-col gap-1 ">
            <div>
              <ul class=" list-inside">
                <li className="text-blue  text-xl lg:text-[22px] hellix-font font-semibold">
                  Guidance on Eligibility and Requirements:{" "}
                  <span className="font-normal">
                    We assess both employer and applicant eligibility, ensuring
                    every requirement is met before application.{" "}
                  </span>
                </li>
              </ul>
            </div>
            <div>
              <ul class="list-inside">
                <li className="text-blue text-xl lg:text-[22px]  hellix-font font-semibold">
                  Labor Market Testing Support:
                  <span className="font-normal">
                    We offer expertise in preparing and documenting labor market
                    testing requirements to satisfy government criteria.
                  </span>
                </li>
              </ul>
            </div>
            <div>
              <ul class="list-inside">
                <li className="text-blue text-xl lg:text-[22px]  hellix-font font-semibold">
                  Tailored Advice on Visa Pathways:
                  <span className="font-normal">
                    Whether temporary or permanent residency is your goal, we
                    help align the best visa pathway to meet long-term
                    aspirations.
                  </span>
                </li>
              </ul>
            </div>
            <div>
              <ul class="list-inside">
                <li className="text-blue text-xl lg:text-[22px]  hellix-font font-semibold">
                  Comprehensive Application Management:
                  <span className="font-normal">
                    We handle the paperwork, liaise with the Department of Home
                    Affairs, and keep you updated every step of the way.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/*FAQ*/}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10  lg:px-14">
        <div className="lg:text-[38px] text-2xl text-center text-blue font-semibold hellix-font">
          Subclass 400 Temporary Work (Short Stay
          <br />
          Specialist) Visa FAQs
        </div>
        <div className="text-blue mt-3 font-normal text-xl lg:text-[22px] text-center  hellix-font">
          Our migration agents are experts in navigating complex processes,
          offering tailored solutions with professionalism and care. Trust us to
          provide transparent, efficient, and reliable support for a seamless
          journey to your new destination.
        </div>

        <div className="accordion-container mt-8">
          {/* Accordion Section 1 */}
          <div className="accordion">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(1)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                1. Need a Temporary Work Visa? Apply for Subclass 400 in
                Australia!{" "}
              </h2>
              {activeIndex === 1 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 1 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  The Subclass 187 visa is a permanent residence visa for
                  skilled workers in regional Australia.
                </p>
              </div>
            )}
          </div>

          {/* Accordion Section 2 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(2)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                2. What are the benefits of the Subclass 187 visa?{" "}
              </h2>
              {activeIndex === 2 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 2 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Benefits include permanent residence, work for the sponsoring
                  employer, and a pathway to citizenship.
                </p>
              </div>
            )}
          </div>

          {/* Accordion Section 3 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(3)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                3. Who is eligible for the Subclass 187 visa?{" "}
              </h2>
              {activeIndex === 3 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 3 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Skilled workers nominated by an approved employer, meeting
                  age, English language, qualification, and experience
                  requirements.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 4 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(4)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                4. What are the occupation lists for the Subclass 187 visa?{" "}
              </h2>
              {activeIndex === 4 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 4 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Medium and Long-Term Strategic Skills List (MLTSSL),
                  Short-Term Skilled Occupation List (STSOL), and Regional
                  Occupation List (ROL).{" "}
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(5)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                5. What are the nomination requirements for the Subclass 187
                visa?{" "}
              </h2>
              {activeIndex === 5 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 5 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Employer must be an approved sponsor, conduct Labour Market
                  Testing (LMT), and demonstrate a genuine need for the
                  position.{" "}
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(6)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                6. How do I apply for the Subclass 187 visa?
              </h2>
              {activeIndex === 6 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 6 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Submit a nomination application, followed by a visa
                  application, with required documents and biometrics.{" "}
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(7)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                7. How long does the Subclass 187 visa application process take?{" "}
              </h2>
              {activeIndex === 7 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 7 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Varies, typically 6-12 months.{" "}
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(8)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                8. Can I apply for citizenship after receiving the Subclass 187
                visa?{" "}
              </h2>
              {activeIndex === 8 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 8 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Yes, after meeting residency requirements.{" "}
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(9)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                9. What streams are available under the Subclass 187 visa?{" "}
              </h2>
              {activeIndex === 9 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 9 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Direct Entry Stream, Temporary Residence Transition Stream,
                  and Labour Agreement Stream.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(10)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                10. How can Immiget Global assist with my Subclass 187 visa
                application?{" "}
              </h2>
              {activeIndex === 10 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 10 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Our experts provide guidance on eligibility, sponsorship,
                  nomination, and visa applications.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <Contact />
    </div>
  );
};

export default EmployeeTemporaryVisa;

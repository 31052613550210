import React from "react";
import Banner from "../banner";
// import Question from "../question";
import Partner from "../partner";
import About from "../about";
import Service from "../service.jsx";
import Contact from "../contact.jsx";
import { Helmet } from "react-helmet-async";
// import HolidayBanner from "../HolidayBanner.jsx";
// import Client from "../client.jsx";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Your Expert Migration Agent|Immiget Global Australia</title>
        <meta
          name="description"
          content="Immiget Global Australia provides expert visa services with a trusted registered migration agent in Australia. Your pathway to success!"
        />
      </Helmet>
      <Banner />
      <About />
      <Service />
      <Partner />
      {/* <Client /> */}
      <Contact />
      {/* <Question /> */}
    </>
  );
};

export default Home;

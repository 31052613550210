import React, { useState } from "react";
import Contact from "../contact";
import arrowIcon from "../../images/home/arrow-icon.svg";
import ellipticalIcon from "../../images/individual/ellipticalIcon.svg";
import helpIcon from "../../images/individual/helpIcon.svg";
import { FaPlus, FaTimes } from "react-icons/fa";
import skillAssesments from "../../images/individual/Skill-Assessment.jpg";
import guardianImage from "../../images/individual/guardian.png";
import LogoCarousel from "../logoCarousel";
import logoOne from "../../images/logos/AIMS.png";
import logoTwo from "../../images/logos/AITSL.png";
import logoThree from "../../images/logos/IPA.png";
import logoFour from "../../images/logos/TRA.png";
import logoFive from "../../images/logos/Vetassess.png";
import logoSix from "../../images/logos/aasw.png";
import logoSeven from "../../images/logos/acs.png";
import logoEight from "../../images/logos/anmac.png";
import logoNine from "../../images/logos/cpa.jpg";
import logoTen from "../../images/logos/cwah.png";
import logoell from "../../images/logos/ea.png";
import logotwl from "../../images/logos/iomal.png";
import { Helmet } from "react-helmet-async";

const SkillAssesment = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const clientLogos = [
    { id: 1, src: logoOne, alt: "Client 1" },
    { id: 2, src: logoTwo, alt: "Client 2" },
    { id: 3, src: logoThree, alt: "Client 3" },
    { id: 4, src: logoFour, alt: "Client 4" },
    { id: 5, src: logoFive, alt: "Client 5" },
    { id: 6, src: logoSix, alt: "Client 6" },
    { id: 7, src: logoSeven, alt: "Client 7" },
    { id: 8, src: logoEight, alt: "Client 8" },
    { id: 9, src: logoNine, alt: "Client 9" },
    { id: 10, src: logoTen, alt: "Client 10" },
    { id: 11, src: logoell, alt: "Client 11" },
    { id: 12, src: logotwl, alt: "Client 12" },
  ];

  return (
    <div>
      <Helmet>
        <title>
          {" "}
          Trusted Skill Assessment Consultants|Immiget Global Australia
        </title>
        <meta
          name="description"
          content="Need a skill assessment for your Australian visa? Immiget Global Australia provides expert consultation for a smooth and fast approval process."
        />
      </Helmet>
      <div className="w-full h-90 font-Hellix bg-cover bg-center bg-image">
        <div className="max-w-[1440px] px-4 md:px-[75px] m-auto h-full">
          <div className="flex flex-col gap-2 md:gap-2 pt-10 md:pt-20 pb-[90px] h-full justify-center">
            <div className="text-white  hellix-font  text-center font-semibold text-[22px] md:text-[54px]">
              Skill Assessment
            </div>
            <div className="flex justify-center  gap-2 md:gap-4">
              <div className="text-white  hellix-font text-[10px]  lg:text-sm flex items-center md:text-lg font-medium">
                HOME
              </div>
              <div className="flex items-center">
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
              </div>
              <div className="text-white hellix-font text-[10px]  lg:text-sm flex items-center md:text-lg font-medium uppercase">
                Individual & Families
              </div>
              <div className="flex items-center">
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
              </div>
              <div className="text-white hellix-font  text-[10px]  lg:text-sm flex items-center md:text-lg font-medium uppercase">
                Skill Assessment
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Subclass 189 Visa: Skilled Independent Visa*/}
      <div className="xl:flex gap-4 justify-center container max-w-1440px  mx-auto px-4  py-4 md:py-10 lg:px-14">
        <div className="xl:w-[50%] ">
          <div className="xl:text-[38px] text-[24px] max-xl:text-center text-blue mt-6 font-semibold hellix-font">
            Immiget Global: Your Trusted Partner for Skill Assessment Success 
          </div>
          <div className=" max-xl:text-center hellix-font text-blue text-lg xl:text-[22px] font-normal ">
            Skill assessments are a crucial step in the Australian immigration
            process, acting as a verification of your qualifications and
            experience. They help determine whether your skills align with
            Australia’s standards and meet the requirements for your intended
            occupation. At Immiget Global, we offer expert guidance to ensure
            that your skill assessment is handled meticulously, increasing your
            chances of success. {" "}
          </div>
        </div>
        <div className="xl:w-[50%] max-xl:justify-center max-xl:flex max-xl:mt-4">
          <img src={skillAssesments} alt="banner-image" />
        </div>
      </div>
      <div className="container max-w-1440px  mx-auto px-4   lg:px-14">
        <div style={{ border: "1px solid #cde1ea" }}></div>
      </div>
      {/* Eligibility Criteria:  */}
      <div className=" container max-w-1440px  mx-auto px-4 py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={ellipticalIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px]  text-2xl text-blue font-semibold hellix-font">
            Why Skill Assessment Matters for Australian Immigration? 
          </div>
        </div>

        <div className="flex gap-2 mt-3">
          <div className="text-blue text-lg xl:text-[22px] font-normal hellix-font">
            A skill assessment is a vital component of the Australian migration
            process. It's a formal evaluation of your skills, qualifications,
            and experience to ensure they meet the standards required for your
            occupation in Australia. This verification process is crucial in
            confirming that your credentials align with Australian industry
            standards.
            <br />
            <br />
            Having a positive skill assessment outcome is a mandatory
            requirement for many Australian visa categories, including skilled
            migration visas. It demonstrates your commitment to your profession
            and provides a competitive edge in the Australian job market.
            Moreover, the skill assessment process ensures you've nominated the
            correct occupation that aligns with your skills,
            qualifications, and experience.
          </div>
        </div>
      </div>

      <div className="container max-w-1440px  mx-auto px-4   lg:px-14">
        <div style={{ border: "1px solid #cde1ea" }}></div>
      </div>
      {/* Application  */}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={ellipticalIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl  text-blue font-semibold hellix-font">
            Step-by-Step Guide to the Skill Assessment Process 
          </div>
        </div>
        <div className=" lg:ml-10 hellix-font mt-3">
          <div className="flex gap-2 flex-col">
            <div className=" hellix-font text-lg lg:text-[22px] font-semibold text-blue">
              <span>Step 1:</span> Choosing the Relevant Assessing Authority
            </div>
            <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue">
              Different occupations have designated assessing bodies (e.g.,
              Engineers Australia for engineers, CPA Australia for accountants).
              The first step is to determine the correct assessing authority
              based on your occupation.{" "}
            </div>
          </div>
          <div className="flex gap-2 flex-col mt-2">
            <div className=" hellix-font text-lg lg:text-[22px] font-semibold text-blue">
              <span>Step 2: </span> Documentation Preparation
            </div>
            <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue">
              You will need to gather various documents, including academic
              transcripts, employment references, proof of work experience, and
              any relevant certifications. Our team will guide you on the
              specific requirements of your assessing authority to ensure that
              you provide everything needed for a positive outcome.
            </div>
          </div>
          <div className="flex gap-2 flex-col mt-2">
            <div className=" hellix-font text-lg lg:text-[22px] font-semibold text-blue">
              <span>Step 3:</span> Submission of Application
            </div>
            <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue">
              Once your documents are ready, they must be submitted to the
              relevant assessing authority. Our team assists with the submission
              process, ensuring that your application is complete, accurate, and
              compliant with all guidelines.
            </div>
          </div>
          <div className="flex gap-2 flex-col mt-2">
            <div className=" hellix-font text-lg lg:text-[22px] font-semibold text-blue">
              <span>Step 4:</span> Outcome and Next Steps
            </div>
            <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue">
              Upon receiving a successful skill assessment, you can proceed with
              the points test and other visa requirements. In case of a negative
              outcome, we provide advice on potential options, such as
              re-evaluation or alternative pathways.
            </div>
          </div>
        </div>
      </div>

      <div className="container max-w-1440px  mx-auto px-4   lg:px-14">
        <div style={{ border: "1px solid #cde1ea" }}></div>
      </div>

      {/* Logo Carousel */}

      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10  lg:px-14">
        <div className="flex gap-2">
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            Skill Assessment Authorities in Australia
          </div>
        </div>
        <LogoCarousel logos={clientLogos} />
      </div>

      {/* Why Choose Immiget Global?  */}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10  lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={helpIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            How Immiget Global Simplifies Your Skill Assessment Journey
          </div>
        </div>

        <div className="xl:flex gap-6 mt-4">
          <div className="xl:w-[50%]">
            <img src={guardianImage} alt="helpIcon" className="h-[100%] " />
          </div>
          <div className="xl:w-[50%] max-xl:mt-4 flex flex-col gap-1 ">
            <div>
              <ul class="list-inside">
                <li className="text-blue text-xl lg:text-[22px]  hellix-font font-semibold">
                  Assessment of Eligibility:
                  <span className="font-normal">
                    We help you understand whether your qualifications and
                    experience align with the requirements of the intended
                    occupation. 
                  </span>
                </li>
              </ul>
            </div>
            <div>
              <ul class="list-inside">
                <li className="text-blue text-xl lg:text-[22px]  hellix-font font-semibold">
                  Document Verification:
                  <span className="font-normal">
                    Our experts ensure that all documents meet the strict
                    standards of the assessing authorities. 
                  </span>
                </li>
              </ul>
            </div>
            <div>
              <ul class="list-inside">
                <li className="text-blue text-xl lg:text-[22px]  hellix-font font-semibold">
                  Guidance on Gaps or Deficiencies:
                  <span className="font-normal">
                    If your skills, qualifications, or experience fall short, we
                    help explore ways to address these gaps. 
                  </span>
                </li>
              </ul>
            </div>
            <div>
              <ul class="list-inside">
                <li className="text-blue text-xl lg:text-[22px]  hellix-font font-semibold">
                  Lodgment and Follow-Up:
                  <span className="font-normal">
                    We manage the submission process, including follow-ups with
                    the assessing authority, so that your application progresses
                    smoothly. 
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div>
          <div>
            <div className="text-blue text-xl lg:text-[22px] mt-4  hellix-font font-semibold">
              Trust Immiget Global for Your Skill Assessment Needs 
            </div>
            <div className="text-xl lg:text-[22px] text-blue">
              Navigating the skill assessment process can be complex, but with
              Immiget Global by your side, you gain access to comprehensive
              support and legal expertise tailored to your immigration goals.
              Contact us today to begin your journey with confidence. 
            </div>
          </div>
        </div>
      </div>

      {/*FAQ*/}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10  lg:px-14">
        <div className="lg:text-[38px] text-2xl text-center text-blue font-semibold hellix-font">
          Frequently Asked Questions – Skill Assessment
        </div>
        <div className="text-blue mt-3 font-normal text-xl lg:text-[22px] text-center  hellix-font">
          Our migration agents are experts in navigating complex processes,
          offering tailored solutions with professionalism and care. Trust us to
          provide transparent, efficient, and reliable support for a seamless
          journey to your new destination.
        </div>

        <div className="accordion-container mt-8">
          {/* Accordion Section 1 */}
          <div className="accordion">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(1)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                1. Need Skill Assessment for Australia? Get Expert Guidance
                Today! 
              </h2>
              {activeIndex === 1 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 1 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  A skill assessment is a process where an authorized Australian
                  body evaluates your qualifications, skills, and work
                  experience to determine if they meet the standards required
                  for your nominated occupation.{" "}
                </p>
              </div>
            )}
          </div>

          {/* Accordion Section 2 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(2)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                2. Why do I need a skill assessment for an Australian visa? 
              </h2>
              {activeIndex === 2 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 2 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  A skill assessment is often required for skilled migration
                  visas to demonstrate that your skills match the requirements
                  of your nominated occupation in Australia.{" "}
                </p>
              </div>
            )}
          </div>

          {/* Accordion Section 3 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(3)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                3. How do I know which assessing authority applies to my
                occupation? 
              </h2>
              {activeIndex === 3 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 3 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Each occupation on the Skilled Occupation List has an
                  associated assessing authority. Our team can help you identify
                  the correct authority based on your qualifications and
                  occupation.{" "}
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 4 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(4)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                4. What documents are required for a skill assessment? 
              </h2>
              {activeIndex === 4 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 4 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Common documents include educational certificates,
                  transcripts, employment references, and proof of professional
                  experience. The exact requirements vary depending on the
                  assessing authority.{" "}
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(5)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                5. How long does the skill assessment process take? 
              </h2>
              {activeIndex === 5 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 5 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Processing times vary by assessing authority, but most
                  assessments take several weeks to a few months. We can give
                  you an estimated timeline based on your specific case.{" "}
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(6)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                6. What if my skill assessment is unsuccessful? 
              </h2>
              {activeIndex === 6 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 6 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  If you receive a negative outcome, there may be options for
                  re-assessment or alternative pathways. Our consultants will
                  review your case and advise on the best approach.{" "}
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(7)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                7. Can Immiget Global help with an appeal or review if my skill
                assessment is negative? 
              </h2>
              {activeIndex === 7 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 7 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                  Yes, we offer services to assist with re-assessment, appeals,
                  and alternative pathways to help you achieve a positive
                  outcome.{" "}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <Contact />
    </div>
  );
};

export default SkillAssesment;

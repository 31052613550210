import React from "react";
import gsmVisa from "../images/home/GSM Visa.jpg";
import worker from "../images/home/worker-icon.svg";
import EmployerVisa from "../images/home/EmployerSponsoredVisa.jpg";
import employerIcon from "../images/home/employee-icon.svg";
import VisitorVisa from "../images/home/VisitorVisa.jpg";
import visitorIcon from "../images/home/visitor-beg-icon.svg";
import partnerVisa from "../images/home/PartnerVisa.jpg";
import iconPartner from "../images/home/icon-partner.svg";
import parentVisa from "../images/home/ParentVisa.jpg";
import iconParent from "../images/home/icon-parent.svg";
import FamilyVisa from "../images/home/FamilyVisa.jpg";
import iconFamily from "../images/home/Icon-family.svg";
import studentVisa from "../images/home/StudentVisa.jpg";
import iconStudent from "../images/home/icon-student.svg";
import migrationVisa from "../images/home/Assessment-Registration.jpg";
import iconMigration from "../images/home/migration_appeal_icon.svg";
import temporaryVisa from "../images/home/TemporaryActivityVisa.jpg";
import iconTemporary from "../images/home/icon-temparay.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/swiper-bundle.css";
import "./service.css";
import { Link } from "react-router-dom";

const Service = () => {
  return (
    <div className="bg-gradient-to-r to-[#91CBB0] via-[#5E9FA3] from-[#004B6B]">
      <div className="container max-w-[1440px] mx-auto py-10 px-6 lg:px-14">
        {/* Heading Section */}
        <div className="text-center">
          <h1 className="hellix-font text-[38px] lg:text-[54px] text-white font-semibold">
            Migration Services We Offer
          </h1>
          <p className="max-w-[1038px] mx-auto hellix-font text-2xl text-white font-300 md:pb-14 mt-4">
            <span className="font-semibold">
              Immiget Global was established in 2020
            </span>{" "}
            with a singular purpose: to bring order and stability to the
            increasingly complex migration landscape. As a leading migration
            consultancy, we are committed to providing expert guidance and
            personalized solutions to individuals and families navigating the
            intricate process of global migration.
          </p>
        </div>

        {/* Service Cards */}
        <div className="desktop-slider grid md:grid-cols-2 lg:grid-cols-3 gap-10">
          {/* Service Card 1 */}
          <div className="bg-white rounded-[15px] max-w-[370px] h-[400px] mx-auto flex-1 ">
            <div className="relative w-full h-auto">
              <img
                src={gsmVisa}
                alt="GSM Visa"
                className="w-full h-full object-cover rounded-t-[15px]"
              />
              <div className="absolute -mb-4 bottom-0 left-1/2 transform -translate-x-1/2 w-[115px] h-[115px] bg-white rounded-full flex items-center justify-center">
                <img
                  src={worker}
                  alt="Worker Icon"
                  className="w-[50px] h-[50px] "
                />
              </div>
            </div>
            <div className="text-center mt-2">
              <h2 className="text-lg md:text-2xl font-medium text-blue hellix-font tracking-wide">
                GSM Visa
              </h2>
              <div className="max-w-[302px] mx-auto h-[1px] bg-blue my-1"></div>
              <p className="text-[13px] sm:text-base text-blue opacity-80 w-[280px] m-auto hellix-font font-normal">
                Expert guidance for skilled migrants seeking permanent
                settlement through General Skilled Migration programs.
              </p>
              <button className="text-sm font-Semibold hellix-font text-white bg-blue  w-[95px] h-[25px] rounded-[155px] mt-2">
                <Link to="/subclass-187-visa-australia-consultants">
                  {" "}
                  Learn More
                </Link>
              </button>
            </div>
          </div>

          {/* Service Card 2 */}
          <div className="bg-white rounded-[15px] max-w-[370px] h-[400px]   mx-auto flex-1">
            <div className="relative w-full h-auto">
              <img
                src={EmployerVisa}
                alt="Employer Sponsored Visa"
                className="w-full h-full object-cover rounded-t-[15px]"
              />
              <div className="absolute  -mb-4  bottom-0 left-1/2 transform -translate-x-1/2 w-[115px] h-[115px] bg-white rounded-full flex items-center justify-center">
                <img
                  src={employerIcon}
                  alt="Employer Sponsored Visa Icon"
                  className="w-[50px] h-[50px]"
                />
              </div>
            </div>
            <div className="text-center mt-3 ">
              <h2 className="text-lg md:text-2xl font-medium text-blue hellix-font tracking-wide">
                Employer Sponsored Visa
              </h2>
              <div className="max-w-[302px] mx-auto h-[1px] bg-blue my-1"></div>
              <p className="text-[13px] sm:text-base text-blue opacity-80  w-[280px] m-auto hellix-font font-normal">
                Secure work opportunities in Australia with our expert
                assistance in Employer Sponsored visa applications.
              </p>
              <button className="text-sm font-Semibold hellix-font text-white bg-blue w-[95px] h-[25px] rounded-[155px] mt-6">
                <Link to="/skills-in-demand-visa-482-australia-consultants">
                  {" "}
                  Learn More
                </Link>
              </button>
            </div>
          </div>

          {/* Service Card 3 */}
          <div className="bg-white rounded-[15px] max-w-[370px] h-[400px]  mx-auto flex-1">
            <div className="relative w-full h-auto">
              <img
                src={VisitorVisa}
                alt="Visitor Visa"
                className="w-full h-full object-cover rounded-t-[15px]"
              />
              <div className="absolute -mb-4  bottom-0 left-1/2 transform -translate-x-1/2 w-[115px] h-[115px] bg-white rounded-full flex items-center justify-center">
                <img
                  src={visitorIcon}
                  alt="Visitor Visa Icon"
                  className="w-[50px] h-[50px]"
                />
              </div>
            </div>
            <div className="text-center mt-3">
              <h2 className="text-lg md:text-2xl font-medium text-blue hellix-font tracking-wide">
                Visitor Visa
              </h2>
              <div className="max-w-[302px] mx-auto h-[1px] bg-blue "></div>
              <p className="text-[13px] leading-[17.90px] sm:text-base text-blue opacity-80 w-[220px] m-auto hellix-font font-normal">
                Streamline your entry to Australia with our Visitor Visa
                services, ensuring hassle-free travel.
              </p>
              <button className="text-sm font-Semibold hellix-font text-white bg-blue w-[95px] h-[25px] rounded-[155px] mt-2">
                <Link to="/australia-visitor-visa-consultants">
                  {" "}
                  Learn More
                </Link>
              </button>
            </div>
          </div>

          {/* Partner Visa */}
          <div className="bg-white rounded-[15px] max-w-[370px] h-[400px]  mx-auto flex-1">
            <div className="relative w-full h-auto">
              <img
                src={partnerVisa}
                alt="Partner Visa"
                className="w-full h-full object-cover rounded-t-[15px]"
              />
              <div className="absolute -mb-4  bottom-0 left-1/2 transform -translate-x-1/2 w-[115px] h-[115px] bg-white rounded-full flex items-center justify-center">
                <img
                  src={iconPartner}
                  alt="Partner Visa Icon"
                  className="w-[50px] h-[50px]"
                />
              </div>
            </div>
            <div className="text-center mt-3">
              <h2 className="text-lg md:text-2xl font-medium text-blue hellix-font tracking-wide">
                Partner Visa
              </h2>
              <div className="max-w-[302px] mx-auto h-[1px] bg-blue "></div>
              <p className="text-[13px] leading-[17.90px] sm:text-base text-blue opacity-80 w-[220px] m-auto hellix-font font-normal">
                Reunite with loved ones through our comprehensive Partner Visa
                application services.
              </p>
              <button className="text-sm font-Semibold hellix-font text-white bg-blue w-[95px] h-[25px] rounded-[155px] mt-2">
                <Link to="/partner-visa-australia"> Learn More</Link>
              </button>
            </div>
          </div>

          {/* Parent Visa */}
          <div className="bg-white rounded-[15px] max-w-[370px] h-[400px] mx-auto flex-1">
            <div className="relative w-full h-auto">
              <img
                src={parentVisa}
                alt="Parent Visa"
                className="w-full h-full object-cover rounded-t-[15px]"
              />
              <div className="absolute -mb-4  bottom-0 left-1/2 transform -translate-x-1/2 w-[115px] h-[115px] bg-white rounded-full flex items-center justify-center">
                <img
                  src={iconParent}
                  alt="Parent Visa Icon"
                  className="w-[50px] h-[50px]"
                />
              </div>
            </div>
            <div className="text-center mt-4">
              <h2 className="text-lg md:text-2xl font-medium text-blue hellix-font tracking-wide">
                Parent Visa
              </h2>
              <div className="max-w-[302px] mx-auto h-[1px] bg-blue my-1"></div>
              <p className="text-[13px] sm:text-base text-blue opacity-80 w-[210px] m-auto hellix-font font-normal">
                Bring your family together with our expert assistance in Parent
                Visa applications
              </p>
              <button className="text-sm font-Semibold hellix-font text-white bg-blue w-[95px] h-[25px] rounded-[155px] mt-4">
                <Link to="/subclass-143-parent-visa-australia-consultants">
                  {" "}
                  Learn More
                </Link>
              </button>
            </div>
          </div>

          {/* Family Visa */}
          <div className="bg-white rounded-[15px] max-w-[370px] h-[400px]  mx-auto flex-1">
            <div className="relative w-full h-auto">
              <img
                src={FamilyVisa}
                alt="Family Visa"
                className="w-full h-full object-cover rounded-t-[15px]"
              />
              <div className="absolute -mb-4  bottom-0 left-1/2 transform -translate-x-1/2 w-[115px] h-[115px] bg-white rounded-full flex items-center justify-center">
                <img
                  src={iconFamily}
                  alt="Family Visa Icon"
                  className="w-[50px] h-[50px]"
                />
              </div>
            </div>
            <div className="text-center mt-4">
              <h2 className="text-lg md:text-2xl font-medium text-blue hellix-font tracking-wide">
                Family Visa
              </h2>
              <div className="max-w-[302px] mx-auto h-[1px] bg-blue "></div>
              <p className="text-[13px] sm:text-base text-blue opacity-80 w-[220px] m-auto hellix-font font-normal">
                Secure a better life for your family with our Family Visa
                service, ensuring timely reunification. 
              </p>
              <button className="text-sm font-Semibold hellix-font text-white bg-blue w-[95px] h-[25px] rounded-[155px] mt-1">
                <Link to="/family-sponsorship-visa-australia-consultants">
                  {" "}
                  Learn More
                </Link>
              </button>
            </div>
          </div>

          <div className="bg-white rounded-[15px] max-w-[370px] h-[400px]  mx-auto flex-1">
            <div className="relative w-full h-auto">
              <img
                src={studentVisa}
                alt="Student Visa"
                className="w-full h-full object-cover rounded-t-[15px]"
              />
              <div className="absolute -mb-4  bottom-0 left-1/2 transform -translate-x-1/2 w-[115px] h-[115px] bg-white rounded-full flex items-center justify-center">
                <img
                  src={iconStudent}
                  alt="Student Visa Icon"
                  className="w-[50px] h-[50px]"
                />
              </div>
            </div>
            <div className="text-center mt-2">
              <h2 className="text-lg md:text-2xl font-medium text-blue hellix-font tracking-wide">
                Student Visa
              </h2>
              <div className="max-w-[302px] mx-auto h-[1px] bg-blue my-1"></div>
              <p className="text-[13px] sm:text-base text-blue opacity-80 w-[240px] m-auto hellix-font font-normal">
                Pursue educational dreams in Australia without any visa-hassle
                through our Student Visa service
              </p>
              <button className="text-sm font-Semibold hellix-font text-white bg-blue w-[95px] h-[25px] rounded-[155px] mt-6">
                <Link to="/subclass-500-student-visa-australia-consultants">
                  {" "}
                  Learn More
                </Link>
              </button>
            </div>
          </div>

          {/* Migration Appeal */}
          <div className="bg-white rounded-[15px] max-w-[370px] h-[400px]  mx-auto flex-1">
            <div className="relative w-full h-auto">
              <img
                src={migrationVisa}
                alt="Migration Appeal"
                className="w-full h-full object-cover rounded-t-[15px]"
              />
              <div className="absolute -mb-4  bottom-0 left-1/2 transform -translate-x-1/2 w-[115px] h-[115px] bg-white rounded-full flex items-center justify-center">
                <img
                  src={iconMigration}
                  alt="Migration Appeal Icon"
                  className="w-[50px] h-[50px]"
                />
              </div>
            </div>
            <div className="text-center mt-2">
              <h2 className="text-lg md:text-2xl font-medium text-blue hellix-font tracking-wide">
                Skill Assessment & Registration.
              </h2>
              <div className="max-w-[302px] mx-auto h-[1px] bg-blue my-1"></div>
              <p className="text-[13px] sm:text-base text-blue opacity-80 w-[300px] m-auto hellix-font font-normal">
                Challenge adverse visa decisions with our experienced review
                team
              </p>
              <button className="text-sm font-Semibold hellix-font text-white bg-blue w-[95px] h-[25px] rounded-[155px] mt-6">
                <Link to="/skill-assessment-australia-consultants">
                  {" "}
                  Learn More
                </Link>
              </button>
            </div>
          </div>

          {/* Temporary Activity Visa */}
          <div className="bg-white rounded-[15px] max-w-[370px] h-[400px]  mx-auto flex-1">
            <div className="relative w-full h-auto">
              <img
                src={temporaryVisa}
                alt="Temporary Activity Visa"
                className="w-full h-full object-cover rounded-t-[15px]"
              />
              <div className="absolute -mb-4  bottom-0 left-1/2 transform -translate-x-1/2 w-[115px] h-[115px] bg-white rounded-full flex items-center justify-center">
                <img
                  src={iconTemporary}
                  alt="Temporary Activity Visa Icon"
                  className="w-[50px] h-[50px]"
                />
              </div>
            </div>
            <div className="text-center mt-2">
              <h2 className="text-lg md:text-2xl font-medium text-blue hellix-font tracking-wide">
                Temporary Activity Visa
              </h2>
              <div className="max-w-[302px] mx-auto h-[1px] bg-blue my-1"></div>
              <p className="text-[13px] sm:text-base text-blue opacity-80 w-[250px] m-auto hellix-font font-normal">
                Facilitate short-term work, training, or events in Australia
                through our Temporary Activity Visa services. 
              </p>
              <button className="text-sm font-Semibold hellix-font text-white bg-blue w-[95px] h-[25px] rounded-[155px] mt-2">
                <Link to="/skills-in-demand-visa-482-australia-consultants">
                  {" "}
                  Learn More
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="responsive-slider container max-w-[1440px] mx-auto px-6 lg:px-14">
        <Swiper
          modules={[Autoplay, Navigation, Pagination]} // Use modules here
          spaceBetween={30}
          slidesPerView={1}
          // navigation={true}
          // pagination={{ clickable: true }}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
        >
          <SwiperSlide>
            {/* Service Card 1 */}
            <div className="bg-white rounded-[15px] max-w-[363px] h-[] mx-auto flex-1">
              <div className="relative w-full h-auto">
                <img
                  src={gsmVisa}
                  alt="GSM Visa"
                  className="w-full h-full object-cover rounded-t-[15px]"
                />
                <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[90px] h-[90px] bg-white rounded-full flex items-center justify-center">
                  <img
                    src={worker}
                    alt="Worker Icon"
                    className="w-[50px] h-[50px]"
                  />
                </div>
              </div>
              <div className="text-center mt-2 pb-3">
                <h2 className="text-lg md:text-2xl font-medium text-blue hellix-font tracking-wide">
                  GSM Visa
                </h2>
                <div className="max-w-[302px] mx-auto h-[1px] bg-blue my-1"></div>
                <p className="text-[13px] sm:text-base text-blue opacity-80 w-[280px] m-auto hellix-font font-normal">
                  Expert guidance for skilled migrants seeking permanent
                  settlement through General Skilled Migration programs.
                </p>
                <button className="text-sm font-Semibold hellix-font text-white bg-blue  w-[95px] h-[25px] rounded-[155px] mt-2">
                  <Link to="/subclass-187-visa-australia-consultants">
                    {" "}
                    Learn More
                  </Link>
                </button>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            {/* Service Card 2 */}
            <div className="bg-white rounded-[15px] max-w-[363px] h-[]   mx-auto flex-1">
              <div className="relative w-full h-auto">
                <img
                  src={EmployerVisa}
                  alt="Employer Sponsored Visa"
                  className="w-full h-full object-cover rounded-t-[15px]"
                />
                <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[90px] h-[90px] bg-white rounded-full flex items-center justify-center">
                  <img
                    src={employerIcon}
                    alt="Employer Sponsored Visa Icon"
                    className="w-[50px] h-[50px]"
                  />
                </div>
              </div>
              <div className="text-center mt-2 pb-3 ">
                <h2 className="text-lg md:text-2xl font-medium text-blue hellix-font tracking-wide">
                  Employer Sponsored Visa
                </h2>
                <div className="max-w-[302px] mx-auto h-[1px] bg-blue my-1"></div>
                <p className="text-[13px] sm:text-base text-blue opacity-80  w-[280px] m-auto hellix-font font-normal">
                  Secure work opportunities in Australia with our expert
                  assistance in Employer Sponsored visa applications.
                </p>
                <button className="text-sm font-Semibold hellix-font text-white bg-blue w-[95px] h-[25px] rounded-[155px] mt-6">
                  <Link to="/skills-in-demand-visa-482-australia-consultants">
                    {" "}
                    Learn More
                  </Link>
                </button>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            {/* Service Card 3 */}
            <div className="bg-white rounded-[15px] max-w-[363px] h-[]  mx-auto flex-1">
              <div className="relative w-full h-auto">
                <img
                  src={VisitorVisa}
                  alt="Visitor Visa"
                  className="w-full h-full object-cover rounded-t-[15px]"
                />
                <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[90px] h-[90px] bg-white rounded-full flex items-center justify-center">
                  <img
                    src={visitorIcon}
                    alt="Visitor Visa Icon"
                    className="w-[50px] h-[50px]"
                  />
                </div>
              </div>
              <div className="text-center mt-2 pb-3">
                <h2 className="text-lg md:text-2xl font-medium text-blue hellix-font tracking-wide">
                  Visitor Visa
                </h2>
                <div className="max-w-[302px] mx-auto h-[1px] bg-blue "></div>
                <p className="text-[13px] leading-[17.90px] sm:text-base text-blue opacity-80 w-[220px] m-auto hellix-font font-normal">
                  Streamline your entry to Australia with our Visitor Visa
                  services, ensuring hassle-free travel.
                </p>
                <button className="text-sm font-Semibold hellix-font text-white bg-blue w-[95px] h-[25px] rounded-[155px] mt-4">
                  <Link to="/australia-visitor-visa-consultants">
                    {" "}
                    Learn More
                  </Link>
                </button>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            {/* Partner Visa */}
            <div className="bg-white rounded-[15px] max-w-[363px] h-[]  mx-auto flex-1">
              <div className="relative w-full h-auto">
                <img
                  src={partnerVisa}
                  alt="Partner Visa"
                  className="w-full h-full object-cover rounded-t-[15px]"
                />
                <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[115px] h-[115px] bg-white rounded-full flex items-center justify-center">
                  <img
                    src={iconPartner}
                    alt="Partner Visa Icon"
                    className="w-[50px] h-[50px]"
                  />
                </div>
              </div>
              <div className="text-center mt-2 pb-3">
                <h2 className="text-lg md:text-2xl font-medium text-blue hellix-font tracking-wide">
                  Partner Visa
                </h2>
                <div className="max-w-[302px] mx-auto h-[1px] bg-blue "></div>
                <p className="text-[13px] leading-[17.90px] sm:text-base text-blue opacity-80 w-[220px] m-auto hellix-font font-normal">
                  Reunite with loved ones through our comprehensive Partner Visa
                  application services.
                </p>
                <button className="text-sm font-Semibold hellix-font text-white bg-blue w-[95px] h-[25px] rounded-[155px] ">
                  <Link to="/partner-visa-australia"> Learn More</Link>
                </button>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            {/* Parent Visa */}
            <div className="bg-white rounded-[15px] max-w-[363px] h-[] mx-auto flex-1">
              <div className="relative w-full h-auto">
                <img
                  src={parentVisa}
                  alt="Parent Visa"
                  className="w-full h-full object-cover rounded-t-[15px]"
                />
                <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[90px] h-[90px] bg-white rounded-full flex items-center justify-center">
                  <img
                    src={iconParent}
                    alt="Parent Visa Icon"
                    className="w-[50px] h-[50px]"
                  />
                </div>
              </div>
              <div className="text-center mt-2 pb-3">
                <h2 className="text-lg md:text-2xl font-medium text-blue hellix-font tracking-wide">
                  Parent Visa
                </h2>
                <div className="max-w-[302px] mx-auto h-[1px] bg-blue my-1"></div>
                <p className="text-[13px] sm:text-base text-blue opacity-80 w-[210px] m-auto hellix-font font-normal">
                  Bring your family together with our expert assistance in
                  Parent Visa applications
                </p>
                <button className="text-sm font-Semibold hellix-font text-white bg-blue w-[95px] h-[25px] rounded-[155px] mt-2">
                  <Link to="/subclass-143-parent-visa-australia-consultants">
                    {" "}
                    Learn More
                  </Link>
                </button>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      <div className="responsive-slider container max-w-[1440px] mx-auto pt-5 pb-10 px-6 lg:px-14">
        <Swiper
          modules={[Autoplay, Navigation, Pagination]} // Use modules here
          spaceBetween={30}
          slidesPerView={1}
          // navigation={true}
          pagination={{ clickable: true }}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
        >
          <SwiperSlide>
            {/* Family Visa */}
            <div className="bg-white rounded-[15px] max-w-[363px] h-[]  mx-auto flex-1">
              <div className="relative w-full h-auto">
                <img
                  src={FamilyVisa}
                  alt="Family Visa"
                  className="w-full h-full object-cover rounded-t-[15px]"
                />
                <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[90px] h-[90px] bg-white rounded-full flex items-center justify-center">
                  <img
                    src={iconFamily}
                    alt="Family Visa Icon"
                    className="w-[50px] h-[50px]"
                  />
                </div>
              </div>
              <div className="text-center mt-2 pb-3">
                <h2 className="text-lg md:text-2xl font-medium text-blue hellix-font tracking-wide">
                  Family Visa
                </h2>
                <div className="max-w-[302px] mx-auto h-[1px] bg-blue "></div>
                <p className="text-[13px] sm:text-base text-blue opacity-80 w-[220px] m-auto hellix-font font-normal">
                  Secure a better life for your family with our Family Visa
                  service, ensuring timely reunification. 
                </p>
                <button className="text-sm font-Semibold hellix-font text-white bg-blue w-[95px] h-[25px] rounded-[155px] ">
                  <Link to="/family-sponsorship-visa-australia-consultants">
                    {" "}
                    Learn More
                  </Link>
                </button>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            {/* Student Visa */}
            <div className="bg-white rounded-[15px] max-w-[363px] h-[]  mx-auto flex-1">
              <div className="relative w-full h-auto">
                <img
                  src={studentVisa}
                  alt="Student Visa"
                  className="w-full h-full object-cover rounded-t-[15px]"
                />
                <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[90px] h-[90px] bg-white rounded-full flex items-center justify-center">
                  <img
                    src={iconStudent}
                    alt="Student Visa Icon"
                    className="w-[50px] h-[50px]"
                  />
                </div>
              </div>
              <div className="text-center mt-2 pb-3">
                <h2 className="text-lg md:text-2xl font-medium text-blue hellix-font tracking-wide">
                  Student Visa
                </h2>
                <div className="max-w-[302px] mx-auto h-[1px] bg-blue my-1"></div>
                <p className="text-[13px] sm:text-base text-blue opacity-80 w-[240px] m-auto hellix-font font-normal">
                  Pursue educational dreams in Australia without any visa-hassle
                  through our Student Visa service
                </p>
                <button className="text-sm font-Semibold hellix-font text-white bg-blue w-[95px] h-[25px] rounded-[155px] mt-6">
                  <Link to="/subclass-500-student-visa-australia-consultants">
                    {" "}
                    Learn More
                  </Link>
                </button>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            {/* Migration Appeal */}
            <div className="bg-white rounded-[15px] max-w-[363px] h-[]  mx-auto flex-1">
              <div className="relative w-full h-auto">
                <img
                  src={migrationVisa}
                  alt="Migration Appeal"
                  className="w-full h-full object-cover rounded-t-[15px]"
                />
                <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[90px] h-[90px] bg-white rounded-full flex items-center justify-center">
                  <img
                    src={iconMigration}
                    alt="Migration Appeal Icon"
                    className="w-[50px] h-[50px]"
                  />
                </div>
              </div>
              <div className="text-center mt-2 pb-3">
                <h2 className="text-lg md:text-2xl font-medium text-blue hellix-font tracking-wide">
                  Skill Assessment & Registration.
                </h2>
                <div className="max-w-[302px] mx-auto h-[1px] bg-blue my-1"></div>
                <p className="text-[13px] sm:text-base text-blue opacity-80 w-[210px] m-auto hellix-font font-normal">
                  Challenge adverse visa decisions with our experienced review
                  team
                </p>
                <button className="text-sm font-Semibold hellix-font text-white bg-blue w-[95px] h-[25px] rounded-[155px] mt-6">
                  <Link to="/skill-assement-australia-consultants">
                    {" "}
                    Learn More
                  </Link>
                </button>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            {/* Temporary Activity Visa */}
            <div className="bg-white rounded-[15px] max-w-[363px] h-[]  mx-auto flex-1">
              <div className="relative w-full h-auto">
                <img
                  src={temporaryVisa}
                  alt="Temporary Activity Visa"
                  className="w-full h-full object-cover rounded-t-[15px]"
                />
                <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[90px] h-[90px] bg-white rounded-full flex items-center justify-center">
                  <img
                    src={iconTemporary}
                    alt="Temporary Activity Visa Icon"
                    className="w-[50px] h-[50px]"
                  />
                </div>
              </div>
              <div className="text-center mt-2 pb-3">
                <h2 className="text-lg md:text-2xl font-medium text-blue hellix-font tracking-wide">
                  Temporary Activity Visa
                </h2>
                <div className="max-w-[302px] mx-auto h-[1px] bg-blue my-1"></div>
                <p className="text-[13px] sm:text-base text-blue opacity-80 w-[250px] m-auto hellix-font font-normal">
                  Facilitate short-term work, training, or events in Australia
                  through our Temporary Activity Visa services. 
                </p>
                <button className="text-sm font-Semibold hellix-font text-white bg-blue w-[95px] h-[25px] rounded-[155px] mt-2">
                  <Link to="/subclass-400-visa-australia-consultants">
                    {" "}
                    Learn More
                  </Link>
                </button>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Service;

import "./App.css";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";
import Lenis from "@studio-freight/lenis";
import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./components/home/home";
import Contactus from "./components/contactPage/immigration-office-australia-consultants";
import Navbar from "./components/navbar";
import AboutUs from "./components/aboutPage/best-migration-australia-consultants";
import SkillIndependentVisa from "./components/skill-independent-visa/subclass-189-visa-australia-consultants";
import SkillNominatedVisa from "./components/skill-nominated-visa/subclass-190-visa-australia-consultants";
import SkillWorkReligion from "./components/skill-worked-religion/subclass-491-visa-australia-consultants";
import StudentVisa from "./components/student-visa/subclass-500-student-visa-australia-consultants";
import StudentGuardianVisa from "./components/student-guardian-visa/student-guardian-visa";
import SkillAssesment from "./components/skill-assesment/skill-assessment-australia-consultants";
import ParentVisa from "./components/parent-visa/subclass-143-parent-visa-australia-consultants";
import FamilyVisa from "./components/family-visa/family-sponsorship-visa-australia-consultants";
import VisitorVisa from "./components/visitor-visa/australia-visitor-visa-consultants";
import PartnerVisa from "./components/partner-visa/partner-visa-australia";
import EmployeeTrainingVisa from "./components/employee-training-visa/subclass-407-visa-australia-consultants";
import EmployeeSkilledReligion from "./components/employee-skilled-religion/subclass-494-visa-australia-consultants";
import EmployeeTemporaryVisa from "./components/employee-temporary-visa/subclass-400-visa-australia-consultants";
import TemporaryGraduateVisa from "./components/temporary-graduate-visa/subclass-485-visa-australia-consultants";
import EmployeeReligionMigration from "./components/employee-religion-migration/subclass-187-visa-australia-consultants";
import EmployeeTemporarySkilledVisa from "./components/employee-temporary-skilled-vis/skills-in-demand-visa-482-australia-consultants";
import EmployerSponserVisa from "./components/employee-sponser-nominated-visa/employee-sponser";
import EmployeeNominatedSchemaVisa from "./components/employee-nominated-schema-visa/subclass-186-visa-australia-consultants";
import watsappicon from "../src/images/home/watsapp-icon.png";

// Smooth Scroll Component
function SmoothScroll({ children }) {
  const lenisRef = useRef();
  const location = useLocation();

  useEffect(() => {
    // Initialize Lenis smooth scroll
    lenisRef.current = new Lenis({
      duration: 1.2,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
      direction: "vertical",
      gestureDirection: "vertical",
      smooth: true,
      mouseMultiplier: 1,
      smoothTouch: false,
      touchMultiplier: 2,
      infinite: false,
    });

    // RAF animation function for Lenis
    function raf(time) {
      lenisRef.current.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    return () => {
      // Clean up Lenis instance on component unmount
      lenisRef.current.destroy();
    };
  }, []);

  // Reset scroll position when route changes
  useEffect(() => {
    window.scrollTo(0, 0);

    // Optional: You can add a small delay before re-enabling smooth scrolling after navigation
    lenisRef.current?.stop();
    setTimeout(() => {
      lenisRef.current?.start();
    }, 100);
  }, [location.pathname]);

  return children;
}

function App() {
  return (
    <BrowserRouter basename="/">
      <SmoothScroll>
        <div className="App">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Header />
                  <Home />
                  <Footer />
                </>
              }
            />
            <Route
              path="/immigration-office-australia-consultants"
              element={
                <>
                  <Navbar />
                  <Contactus />
                  <Footer />
                </>
              }
            />
            <Route
              path="/best-migration-australia-consultants"
              element={
                <>
                  <Navbar />
                  <AboutUs />
                  <Footer />
                </>
              }
            />
            <Route
              path="/subclass-189-visa-australia-consultants"
              element={
                <>
                  <Navbar />
                  <SkillIndependentVisa />
                  <Footer />
                </>
              }
            />
            <Route
              path="/subclass-190-visa-australia-consultants"
              element={
                <>
                  <Navbar />
                  <SkillNominatedVisa />
                  <Footer />
                </>
              }
            />
            <Route
              path="/subclass-491-visa-australia-consultants"
              element={
                <>
                  <Navbar />
                  <SkillWorkReligion />
                  <Footer />
                </>
              }
            />
            <Route
              path="/subclass-500-student-visa-australia-consultants"
              element={
                <>
                  <Navbar />
                  <StudentVisa />
                  <Footer />
                </>
              }
            />
            <Route
              path="/student-guardian-visa"
              element={
                <>
                  <Navbar />
                  <StudentGuardianVisa />
                  <Footer />
                </>
              }
            />
            <Route
              path="/skill-assessment-australia-consultants"
              element={
                <>
                  <Navbar />
                  <SkillAssesment />
                  <Footer />
                </>
              }
            />
            <Route
              path="/subclass-143-parent-visa-australia-consultants"
              element={
                <>
                  <Navbar />
                  <ParentVisa />
                  <Footer />
                </>
              }
            />
            <Route
              path="/family-sponsorship-visa-australia-consultants"
              element={
                <>
                  <Navbar />
                  <FamilyVisa />
                  <Footer />
                </>
              }
            />
            <Route
              path="/partner-visa-australia"
              element={
                <>
                  <Navbar />
                  <PartnerVisa />
                  <Footer />
                </>
              }
            />
            <Route
              path="/australia-visitor-visa-consultants"
              element={
                <>
                  <Navbar />
                  <VisitorVisa />
                  <Footer />
                </>
              }
            />
            <Route
              path="/subclass-407-visa-australia-consultants"
              element={
                <>
                  <Navbar />
                  <EmployeeTrainingVisa />
                  <Footer />
                </>
              }
            />
            <Route
              path="/subclass-494-visa-australia-consultants"
              element={
                <>
                  <Navbar />
                  <EmployeeSkilledReligion />
                  <Footer />
                </>
              }
            />
            <Route
              path="/subclass-400-visa-australia-consultants"
              element={
                <>
                  <Navbar />
                  <EmployeeTemporaryVisa />
                  <Footer />
                </>
              }
            />
            <Route
              path="/subclass-485-visa-australia-consultants"
              element={
                <>
                  <Navbar />
                  <TemporaryGraduateVisa />
                  <Footer />
                </>
              }
            />
            <Route
              path="/subclass-187-visa-australia-consultants"
              element={
                <>
                  <Navbar />
                  <EmployeeReligionMigration />
                  <Footer />
                </>
              }
            />
            <Route
              path="/skills-in-demand-visa-482-australia-consultants"
              element={
                <>
                  <Navbar />
                  <EmployeeTemporarySkilledVisa />
                  <Footer />
                </>
              }
            />
            <Route
              path="/employee-sponser-visa"
              element={
                <>
                  <Navbar />
                  <EmployerSponserVisa />
                  <Footer />
                </>
              }
            />
            <Route
              path="/subclass-186-visa-australia-consultants"
              element={
                <>
                  <Navbar />
                  <EmployeeNominatedSchemaVisa />
                  <Footer />
                </>
              }
            />
          </Routes>

          <div className="whatsapp-overlay">
            <a
              href="https://wa.me/61420340811"
              target="_blank"
              rel="noopener noreferrer"
              className="whatsapp-button"
            >
              <img src={watsappicon} alt="WhatsApp" className="whatsapp-icon" />
            </a>
          </div>
        </div>
      </SmoothScroll>
    </BrowserRouter>
  );
}

export default App;
